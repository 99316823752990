import React, { useState, useEffect } from "react";
import DoughnutChart from "../Charts/DonutChart/DoughnutChart";
import { rejectionDataMapper, getOpType } from "../../services/common.utlis";
import { takeASnap } from "../../services/common.utlis";

const RejectionCard = ({ rejectionData }) => {

  const [timeFrame, setTimeFrame] = useState("monthlyData");
  const [opType, setOpType] = useState("");
  const [shift, setShift] = useState("daily");

  const options = getOpType(rejectionData, timeFrame);

  useEffect(() => {
    setOpType(options[0]);
  }, [timeFrame]);

  const chartData = rejectionDataMapper(
    rejectionData,
    timeFrame,
    opType,
    shift
  );

  const totalCount = chartData
    ? chartData["values"].reduce((accu, element) => accu + element, 0)
    : 0;

  const operationsList = options.map(
    (el) =>
      el.includes("OP") && (
        <option
          key={el}
          value={el}
          disabled={el === "OP115" ? "disabled" : false}
        >
          {el}
        </option>
      )
  );

  return (
    <div className="card-container border-blue mt-24 shadow">
      <div className="card-header flex-row bg-light">
        <h3>Rejection Data
          <span className="title-icons">
            <i className="fa fa-pie-chart"></i>
          </span>
        </h3>
        <div className="ml-10">
          <select
            id="timeFrame"
            value={timeFrame}
            onChange={(event) => setTimeFrame(event.target.value)}
          >
            <option value="dailyData">Day</option>
            <option value="monthlyData">Month</option>
          </select>
        </div>
        <span className="cam-icons">
          <i
            className="fa fa-camera-retro ico-lg"
            title="Take a snap"
            onClick={() => takeASnap("#rejection-chart")}
          ></i>
        </span>
      </div>
      <div className="card-body">
        <div className="flex-container">
          <div>
            <select
              id="opType"
              value={opType}
              onChange={(event) => setOpType(event.target.value)}
            >
              {operationsList}
            </select>
          </div>
          <div className="ml-10">
            <select
              id="shiftNum"
              value={shift}
              onChange={(event) => setShift(event.target.value)}
            >
              <option value="shift1">Shift1</option>
              <option value="shift2">Shift2</option>
              <option value="daily">Day</option>
            </select>
          </div>
        </div>
        <div id="rejection-chart">
          <DoughnutChart
            chartTitle=""
            chartLabels={chartData && chartData.labels}
            dataGroup={chartData && chartData.values}
            iconClass="fa fa-pie-chart"
          />
        </div>
      </div>
      <div className="card-footer flex-row bg-light">
        <h4 className="flaot-right">
          Total Rejection<span>: {totalCount.toLocaleString("en-IN")}</span>
        </h4>
      </div>
    </div>
  );
};

export default RejectionCard;