import React from "react";
import NoData from "../NoData/NoData";

const MachineShopInfoCard = ({ machine, data }) => {

  const createShiftTable2 = (category) => {
    const opLength = data[category]?.operator?.length;
    const insLength = data[category]?.line_inspector?.length;
    const supLength = data[category]?.supervisor?.length;
    const maxLength = opLength > insLength && opLength > supLength ? opLength : insLength > supLength ? insLength :supLength;
    let tableString = "";
    // console.log("logging ", data.shift1[category], data.shift1[category], maxLength)
    for (let i = 0; i < maxLength; i++) {
      tableString += "<tr class=\"tr\">";
      tableString += "<td class='td text-center'>" + (opLength > i ? data[category]?.operator[i] : '') + "</td>";
      tableString += "<td class='td text-center'>" + (insLength > i ? data[category]?.line_inspector[i] : '') + "</td>";
      tableString += "<td class='td text-center'>" + (supLength > i ? data[category]?.supervisor[i] : '') + "</td>";
      tableString += "</tr>"
    }
    tableString += "";
    return tableString;
  };

  return (
    <div className="card-container flex-row border-blue mt-24 shadow">
      <div className="card-body">
        <h4 className="h4-margin">{machine}</h4>
        <hr className=""/>
        <div className="flex-container">
          <div className="flex-item-2">
            <div className="card-header bg-grey">
              <h3>Shift-1</h3>
            </div>
            <div className="card-body pd-0">
       { data.shift1 ? (<table>
              <thead>
                <tr className="tr">
                  <th>Operators</th>
                  <th>Line Inspectors</th>
                  <th>Supervisors</th>
                </tr>
              </thead>
              <tbody dangerouslySetInnerHTML={{ __html: createShiftTable2("shift1") }}></tbody>
              </table>) : <NoData />}
            </div>
          </div>
          <div className="flex-item-2 ml-2">
            <div className="card-header bg-grey">
              <h3>Shift-2</h3>
            </div>
            <div className="card-body pd-0">
          { data.shift2 ? (<table>
              <thead>
                <tr className="tr">
                  <th>Operators</th>
                  <th>Line Inspectors</th>
                  <th>Supervisors</th>
                </tr>
              </thead>
              <tbody dangerouslySetInnerHTML={{ __html: createShiftTable2("shift2") }}></tbody>
              </table>) : <NoData />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineShopInfoCard;
