import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

//components
import StyledPageContainer from "../../components/StyledPageContainer/StyledPageContainer";
import StyledPageHeader from "../../components/StyledPageHeader/StyledPageHeader";
import StyledTable from '../../components_u4/ReactTable/StyledTable'
import StyledTableContainer from "../../components/StyledTableContainer/StyledTableContainer";
import StyledEmptyHeader from "../../components/StyledEmptyHeader/StyledEmptyHeader";
import StyledSelectField from "../../components/StyledSelectField/StyledSelectField";
import StyledTextFieldWithLabel from "../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel";
import PartUploadModal from "./PartUploadModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";

import {
  useErrorTokenLoading,
  loading,
  failure,
  success,
} from '../../context/ErrorTokenLoadingContext'

//assets
import { DeleteIcon, EditIcon } from "../../icons/Icons";

//utils
import { showNotification } from "../../utils/toast.utils";
import { MACHINES } from "../../utils/constant.utils";

//services
import partService from "../../services/part.service";

const machineOptions = MACHINES

function checkCycleTimeFormat(cycleTime, type) {
  const data = {
    success: false,
    data: 0,
    message: null,
  };

  //1. Check if the string includes :
  const cycleTimeString = String(cycleTime);

  if (!cycleTimeString.includes(":")) {
    data.success = false;
    data.message = `Missing ':' ${type} Should be in mm:ss format`;
    return data;
  }

  const arr = cycleTimeString.split(":");

  if (arr.length !== 2) {
    data.success = false;
    data.message = ` ${type} Should be in mm:ss format`;
    return data;
  }

  if (Number(arr[0]) >= 60) {
    data.success = false;
    data.message = `Minutes should be less than 60 in ${type}`;
    return data;
  }

  if (Number(arr[1]) >= 60) {
    data.success = false;
    data.message = `Seconds should be less than 60 in ${type}`;
    return data;
  }

  return {
    success: true,
    data: Number(arr[0]) * 60 + Number(arr[1]),
  };
}

const PartManagement = () => {
  const { token } = useErrorTokenLoading()
  const defaultMachine = {
    value: 0,
    label: "Select machine",
  };

  const [partRows, setPartRows] = useState([]);
  const [partName, setPartName] = useState("");
  const [partType, setPartType] = useState("");
  const [machine, setMachine] = useState(defaultMachine);
  const [isLoading, setIsLoading] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [openAddPartModal, setOpenAddPartModal] = useState(false);
  const [selectedPart, setSelectedPart] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const fetchParts = async () => {
    console.log("machine", machine);
    const data = {};

    if (partName !== "") {
      data.partName = partName.trim();
    }

    if (partType !== "") {
      data.partType = partType.trim();
    }

    if (machine.value !== 0) {
      data.machine = machine.value;
    }

    try {
      setIsLoading(true);
      const partsResponse = await partService.getParts(data, token);
      const partsData = partsResponse.data.map((part, i) => {
        return {
          index: i + 1,
          id: part.id,
          name: part.name,
          type: part.type,
          machine: part.machine,
          machiningTime: part.machiningTime,
          loadUnloadTime: part.loadUnloadTime,
          createdBy: part.createdBy,
        };
      });
      setPartRows(partsData);
    } catch (err) {
      console.log(err);
      setPartRows([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (machine === null) {
      return;
    }
    fetchParts();
  }, [machine]);

  const addPart = async (partData) => {
    const { machiningTime, loadUnloadTime, partName, partType, machine } =
      partData;

    if (!partName) {
      showNotification("Part name is required", "error");
      return;
    }

    if (!partType) {
      showNotification("Part type is required", "error");
      return;
    }

    if (machine.value === 0) {
      showNotification("Machine is required", "error");
      return;
    }

    const machineTimeValidation = checkCycleTimeFormat(
      machiningTime,
      "Machining time"
    );

    if (!machineTimeValidation.success) {
      showNotification(machineTimeValidation.message, "error");
      return;
    }

    const loadUnloadTimeValidation = checkCycleTimeFormat(
      loadUnloadTime,
      "Load and unload time"
    );

    if (!loadUnloadTimeValidation.success) {
      showNotification(loadUnloadTimeValidation.message, "error");
      return;
    }

    const data = {
      createdBy: "user1",
      partName,
      partType,
      machine: machine.value,
      machiningTime,
      loadUnloadTime,
    };

    try {
      setIsLoading(true);
      const partsResponse = await partService.addPart(data, token);
      console.log(partsResponse);
      if (partsResponse.data.success) {
        showNotification(`Part added successfully`, "success");
      } else {
        showNotification(partsResponse.data.message, "error");
      }
      setOpenAddPartModal(false);
      fetchParts();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const editPart = async (partData) => {
    const { id, machiningTime, loadUnloadTime, partName, partType, machine } =
      partData;

    if (!partName) {
      showNotification("Part name is required", "error");
      return;
    }

    if (!partType) {
      showNotification("Part type is required", "error");
      return;
    }

    if (machine.value === 0) {
      showNotification("Machine is required", "error");
      return;
    }

    const machineTimeValidation = checkCycleTimeFormat(
      machiningTime,
      "Machining time"
    );

    if (!machineTimeValidation.success) {
      showNotification(machineTimeValidation.message, "error");
      return;
    }

    const loadUnloadTimeValidation = checkCycleTimeFormat(
      loadUnloadTime,
      "Load and unload time"
    );

    if (!loadUnloadTimeValidation.success) {
      showNotification(loadUnloadTimeValidation.message, "error");
      return;
    }

    const data = {
      updatedBy: "user1",
      partName,
      partType,
      machine: machine.value,
      machiningTime,
      loadUnloadTime,
    };

    try {
      setIsLoading(true);
      const partsResponse = await partService.editPart(id, data, token);
      if (partsResponse.status === 200) {
        showNotification(`Part updated successfully`, "success");
      } else {
        showNotification(partsResponse.data.message, "error");
      }
      setOpenAddPartModal(false);
      fetchParts();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setIsEditMode(false);
    }
  };

  const deletePart = async (partId) => {
    if (!partId) {
      showNotification("part id is required", "error");
      return;
    }

    try {
      setIsLoading(true);
      const partResponse = await partService.deletePart(partId, token);
      if (partResponse.data.success) {
        showNotification(`Part deleted successfully`, "success");
      } else {
        showNotification(partResponse.data.message, "error");
      }
      fetchParts();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const clearAll = async () => {
    setPartName("");
    setPartType("");
    setMachine(defaultMachine);
    await fetchParts();
  };

  const partColumns = [
    {
      Header: "S.No",
      accessor: "index",
    },
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Part number",
      accessor: "name",
    },
    {
      Header: "Operation",
      accessor: "type",
    },
    {
      Header: "Machine",
      accessor: "machine",
    },
    {
      Header: "Machining time (mm:ss)",
      accessor: "machiningTime",
    },
    {
      Header: "Load & unload time (mm:ss)",
      accessor: "loadUnloadTime",
    },
    // {
    //   Header: "Created by",
    //   accessor: "createdBy",
    // },
    {
      Header: "Edit",
      Cell: ({ cell: { row } }) => {
        return (
          <EditIcon
            onClickHandler={() => {
              setIsEditMode(true);
              setSelectedPart({ ...row.values });
              setOpenAddPartModal(true);
            }}
          />
        );
      },
    },
    {
      Header: "Delete",
      Cell: ({ cell: { row } }) => {
        return (
          <DeleteIcon
            onClickHandler={() => {
              setOpenDeleteModal(true);
              setSelectedPart({ ...row.values });
            }}
          />
        );
      },
    },
  ];

  if (isLoading) {
    return <div>Loading.....</div>;
  }

  return (
    <StyledPageContainer>
      <StyledPageHeader title="Manage parts" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-between",
          alignItems: "center",
          columnGap: "16px",
          padding: "16px 0",
        }}
      >
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setIsEditMode(false);
              setSelectedPart({});
              setOpenAddPartModal(true);
            }}
          >
            Add part
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-between",
            marginLeft: "auto",
            columnGap: "16px",
            padding: "16px 0",
          }}
        >
          <Button
            startIcon={<ClearIcon />}
            variant="contained"
            onClick={clearAll}
          >
            Clear
          </Button>
          <Button
            startIcon={<SearchIcon />}
            variant="contained"
            onClick={fetchParts}
          >
            Search
          </Button>
        </div>
      </div>
      <Stack
        spacing={2}
        direction="row"
        sx={{ marginBottom: "16px", width: "100%", alignItems: "center" }}
      >
        <StyledTextFieldWithLabel
          label="Part number"
          placeholderText="Enter part number"
          textValue={partName}
          onChangeHandler={(e) => setPartName(e.target.value)}
          isDisabled={false}
          type="text"
        />
        <StyledTextFieldWithLabel
          label="Operation type"
          placeholderText="Enter operation type"
          textValue={partType}
          onChangeHandler={(e) => setPartType(e.target.value)}
          isDisabled={false}
          type="text"
        />
        <StyledSelectField
          label="Machine"
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={machineOptions}
          onChangeHandler={(value) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
      </Stack>

      <StyledTableContainer>
        {!isLoading && partRows.length === 0 && (
          <StyledEmptyHeader>There are no parts</StyledEmptyHeader>
        )}
        {partRows.length > 0 && (
          <StyledTable
            pagination={true}
            columns={partColumns}
            data={partRows}
            hiddenColumns={["id"]}
          />
        )}
      </StyledTableContainer>
      {openAddPartModal && (
        <PartUploadModal
          closeHandler={() => setOpenAddPartModal(false)}
          isModalOpen={openAddPartModal}
          addPart={addPart}
          editPart={editPart}
          isEditMode={isEditMode}
          selectedPart={selectedPart}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following variant ${selectedPart.name}_${selectedPart.type}_${selectedPart.machine}`}
          buttonTitle="Delete"
          clickHandler={() => deletePart(selectedPart.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </StyledPageContainer>
  );
};

export default PartManagement;
