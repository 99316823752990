import React from "react";
import classes from "./NotFound.module.css";

const NotFound = () => {
  return (
    <div className={classes.notFound}>
      <i
        className={`fa fa-exclamation-triangle ${classes.notFound_icon}`}
        aria-hidden="true"
      ></i>
      <h1> 404 | Not Found </h1>
    </div>
  );
};

export default NotFound;
