import React from "react";
import {VistaTable} from "vista-table-react";

const VariantwiseTable = ({ variantData, tableTitle }) => {
  const tableHeader = [
    {key:"part_type", title: "Part Type"},
    {key:"shift1_qty", title: "Shift-1"},
    {key:"shift2_qty", title: "Shift-2"},
    {key:"daily_qty", title: "Total"}
  ];

  const colorObject = {
    rowKey: "part_type",
    colors: {
      OP110: 'tr-1',
      OP130: 'tr-2',
      OP140: 'tr-3'
    },
    length: -5
  }

  return (
    <div className="card-container flex-row border-thin mt-5">
      <div className="card-header bg-grey">
        <h3>{tableTitle}</h3>
        <span className="cam-icons">
          <i
            className="fa fa-camera-retro ico-lg"
            title="Take a snap"
            // onClick={() => takeASnap()}
          ></i>
        </span>
      </div>
      <div className="card-body pd-0">
          <VistaTable headers={tableHeader} data={variantData} color={colorObject}  />
      </div>
    </div>
  );
};

export default VariantwiseTable;
