import React from 'react'
import { useNavigate } from 'react-router-dom'

import UserInfo from '../../components/UserInfo/UserInfo'

//styles and assets
import logo from '../../assets/logo.png'
import { Container, MainHeading, CardWrapper, Card, Profile, Logo } from './styled'

const units = [
  {
    name: 'Unit-1',
    path: '/unit-1/dashboard',
    enabled: true,
  },
  // {
  //   name: 'Unit-4',
  //   path: '/unit-4/dashboard',
  //   enabled: true,
  // },
]

const MainPage = () => {
  const navigate = useNavigate()
  const unitChangeHandler = (item) => {
    if (item.enabled) {
      navigate(item.path)
    } else {
      //notify through
    }
  }

  return (
    <Container>
      <Profile>
        <UserInfo />
      </Profile>
      <Logo>
        <img alt='logo' src={logo} />
      </Logo>
      <MainHeading>Welcome to DPS Industry 4.0 solutions</MainHeading>
      <CardWrapper>
        {units.map((item) => {
          return (
            <Card onClick={() => unitChangeHandler(item)}>{item.name}</Card>
          )
        })}
      </CardWrapper>
    </Container>
  )
}

export default MainPage
