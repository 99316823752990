import { styled } from '@mui/material';

export const StyledTableRoot = styled("table")(({ theme }) => ({
  width: "100%",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default,
  borderSpacing: "0 0",
}));

export const StyledTh = styled("th")(({ theme }) => ({
  padding: "24px 0",
  textAlign: "center",
  borderBottom: "1px solid #dee2e6",
}));

export const TrBodyRow = styled("tr")(({ theme }) => ({
  "&:hover": {
    // backgroundColor: theme.palette.background.hover,
  },
}));

export const StyledTd = styled("td")(({ theme }) => ({
  padding: "16px 0",
  textAlign: "center",
  borderBottom: "1px solid #dee2e6",
}));

export const TrChildHeader = styled("tr")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: "none",
}));
