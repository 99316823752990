import React, { useState, useEffect } from 'react'
import BarChart from '../Charts/BarChart/BarChart'
import * as CHART_UTILS from '../Charts/BarChart/BarChart.constant'

const OeeChart = (props) => {
  const { monthData = [], yearData = [] } = props

  const [timePeriod, setTimePeriod] = useState('date_of_job')

  const chartTitle = 'OEE Trend'
  let chartLabels = []
  let shift1Oee = []
  let shift2Oee = []
  let oee = []

  const prepareChartData = (data, timePeriod) => {
    chartLabels = data.map((el) => el[timePeriod])
    shift1Oee = data.map((el) => el['s1_oee'])
    shift2Oee = data.map((el) => el['s2_oee'])
    oee = data.map((el) => el['daily_oee'])
  }

  const dataToUse = timePeriod === 'date_of_job' ? monthData : yearData

  if (dataToUse.length > 0) {
    prepareChartData(dataToUse, timePeriod)
  }

  const dataGroup = [
    {
      ...CHART_UTILS.BAR,
      label: 'Shift-1 OEE',
      data: shift1Oee,
      backgroundColor: '#7afade',
      borderColor: '#04b890',
    },
    {
      ...CHART_UTILS.BAR,
      label: 'Shift-2 OEE',
      data: shift2Oee,
      backgroundColor: '#f5ae67',
      borderColor: '#b55d05',
    },
    {
      ...CHART_UTILS.BAR,
      label: timePeriod === 'date_of_job' ? 'Daily OEE' : 'Monthly OEE',
      data: oee,
      backgroundColor: '#fa9ba6',
      borderColor: '#fa465b',
    },
  ]

  return (
    <div className="card-container flex-row border-blue mt-24 shadow">
      <div className="card-header bg-light">
        <h3>{chartTitle}</h3>
        <div>
          <select
            className="select"
            value={timePeriod}
            onChange={(event) => setTimePeriod(event.target.value)}
          >
            <option value="date_of_job">Daily</option>
            <option value="month">Monthly</option>
          </select>
        </div>
      </div>
      <div className="card-body">
        <BarChart
          chartLabels={chartLabels}
          dataGroup={dataGroup}
          xLabel={timePeriod === 'date_of_job' ? 'DAYS' : 'MONTHS'}
          yLabel="OEE"
        />
      </div>
    </div>
  )
}

export default OeeChart
