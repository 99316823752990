import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'

import ReactTable from '../ReactTable/ReactTable'
import classes from './ProductionTable.module.css'

const shiftMapper = (shift) => {
  if (shift === 'Day') {
    return 'S-1'
  } else if (shift === 'Night') {
    return 'S-2'
  } else {
    return 'NA'
  }
}

const ProductionTable = (props) => {
  const { productionData } = props
  const data = productionData.map((item) => ({
    ...item,
    shift: shiftMapper(item.shift),
  }))

  let machines = []
  let partNames = []
  let partTypes = []
  let operators = []
  let dates = []

  const [filterObject, setFilterObject] = useState({
    machine: 'All',
    shift: 'All',
    partName: 'All',
    operator: 'All',
    partType: 'All',
    dateOfJob: 'All',
  })
  const [columnsToFilter, setColumnsToFilter] = useState([])
  const [filteredRows, setFilteredRows] = useState(data)

  const onFilterChange = (value, key) => {
    setFilterObject((prev) => ({
      ...prev,
      [key]: value,
    }))

    setColumnsToFilter((prev) => {
      let temp = [...prev]
      if (!temp.includes(key)) {
        temp.push(key)
      }

      if (value === 'All' || value === '') {
        temp = temp.filter((column) => column !== key)
      }
      return temp
    })
  }

  useEffect(() => {
    console.log(columnsToFilter)
    console.log(filterObject)
    setFilteredRows(
      [...data].filter((item) => {
        let selected = 0
        columnsToFilter.forEach((column) => {
          selected =
            item[column].toString() === filterObject[column]
              ? selected + 1
              : selected
        })
        return selected === columnsToFilter.length
      })
    )
  }, [columnsToFilter, filterObject, productionData])

  data?.forEach((item) => {
    if (!machines.includes(item['machine'])) {
      machines.push(item['machine'])
    }
    if (!partNames.includes(item['partName'])) {
      partNames.push(item['partName'])
    }
    if (!partTypes.includes(item['partType'])) {
      partTypes.push(item['partType'])
    }
    if (!operators.includes(item['operator'])) {
      operators.push(item['operator'])
    }
    if (!dates.includes(item['dateOfJob'])) {
      dates.push(item['dateOfJob'])
    }
  })

  const productionTableColumns = [
    {
      Header: 'Date',
      accessor: 'dateOfJob',
    },
    {
      Header: 'Shift',
      accessor: 'shift',
    },
    {
      Header: 'Machine',
      accessor: 'machine',
    },
    {
      Header: 'Operator',
      accessor: 'operator',
    },
    {
      Header: 'Part number',
      accessor: 'partName',
    },
    {
      Header: 'Operation type',
      accessor: 'partType',
    },
    {
      Header: 'Target',
      accessor: 'target',
    },
    {
      Header: 'Production qty',
      accessor: 'achievedQuantity',
    },
    {
      Header: 'Rejection qty',
      accessor: 'rejectionTotal',
    },
    {
      Header: 'Production',
      accessor: 'productionEfficiency',
    },
    {
      Header: 'Rejection',
      accessor: 'rejectionPercent',
    },
    {
      Header: 'OEE',
      accessor: 'oee',
    },
    {
      Header: 'Idletime',
      accessor: 'idletimeTotal',
    },
  ]

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardHeader}>
        <h3>Production Data</h3>
        <CSVLink className={classes.exportBtn} data={filteredRows}>
          <p>Export to csv</p>
          <i className='fa fa-download' aria-hidden='true'></i>
        </CSVLink>
      </div>
      <div className={classes.cardBody}>
        <div className={classes.selectContainer}>
          <div className={classes.selectBox}>
            <p className='p-0 m-0'>Date</p>
            <select
              id='dateOfJob'
              value={filterObject.dateOfJob}
              onChange={(e) => onFilterChange(e.target.value, 'dateOfJob')}
            >
              <option value='All'>All</option>
              {dates.map((date) => (
                <option key={date} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div>

          <div className={classes.selectBox}>
            <p className='p-0 m-0'>Shift</p>
            <select
              id='shift'
              value={filterObject.shift}
              onChange={(e) => onFilterChange(e.target.value, 'shift')}
            >
              <option value='All'>All</option>
              <option value='S-1'>S-1</option>
              <option value='S-2'>S-2</option>
            </select>
          </div>
          <div className={classes.selectBox}>
            <p className='p-0 m-0'>Machine</p>
            <select
              id='machine'
              value={filterObject.machine}
              onChange={(e) => onFilterChange(e.target.value, 'machine')}
            >
              <option value='All'>All</option>
              {machines.map((machine) => (
                <option key={machine} value={machine}>
                  {machine}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.selectBox}>
            <p className='p-0 m-0'>Operator</p>
            <select
              id='operator'
              value={filterObject.operator}
              onChange={(e) => onFilterChange(e.target.value, 'operator')}
            >
              <option value='All'>All</option>
              {operators.map((operator) => (
                <option key={operator} value={operator}>
                  {operator}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.selectBox}>
            <p className='p-0 m-0'>Part number</p>
            <select
              id='partName'
              value={filterObject.partName}
              onChange={(e) => onFilterChange(e.target.value, 'partName')}
            >
              <option value='All'>All</option>
              {partNames.map((partName) => (
                <option key={partName} value={partName}>
                  {partName}
                </option>
              ))}
            </select>
          </div>
          <div className={classes.selectBox}>
            <p className='p-0 m-0'>Operation type</p>
            <select
              id='partType'
              value={filterObject.partType}
              onChange={(e) => onFilterChange(e.target.value, 'partType')}
            >
              <option value='All'>All</option>
              {partTypes.map((partType) => (
                <option key={partType} value={partType}>
                  {partType}
                </option>
              ))}
            </select>
          </div>
        </div>
        <ReactTable columns={productionTableColumns} data={filteredRows} />
      </div>
    </div>
  )
}

export default ProductionTable
