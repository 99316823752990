import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import LoadingSpinner from "../LoadingSpinner";

const ModalTitle = styled("p")`
  font-weight: 600;
  font-size: 16px;
`;
const ModalBody = styled("div")`
  width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.default};
  padding: 24px;
  border-radius: 8px;
`;
const ModalContent = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
`;
const ActionButtons = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  margin-top: 16px;
`;
const ConfirmText = styled("p")`
  font-weight: 400;
  font-size: 16px;
`;

export default function ConfirmationModal(props) {
  const {
    clickHandler,
    isModalOpen,
    closeHandler,
    description,
    disableLoading,
  } = props;

  const [modalLoading, setModalLoading] = useState(false);

  const modalClickHandler = async () => {
    setModalLoading(true);

    if (disableLoading) clickHandler();
    else await clickHandler();

    setModalLoading(false);
    closeHandler();
  };

  return (
    <Modal disableEnforceFocus disableAutoFocus open={isModalOpen}>
      <ModalBody>
        <ModalTitle>Are you sure?</ModalTitle>
        <ModalContent>
          <ConfirmText>{description}</ConfirmText>
        </ModalContent>
        <ActionButtons>
          <Button onClick={modalClickHandler} variant="outlined" size="medium">
            <DoneIcon />
          </Button>
          <Button onClick={closeHandler} variant="outlined" size="medium">
            <CloseIcon />
          </Button>
        </ActionButtons>
        <LoadingSpinner loading={modalLoading} />
      </ModalBody>
    </Modal>
  );
}
