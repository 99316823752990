import React, { useState, useEffect } from "react";
import { useErrorTokenLoading, loading, success, failure} from "../../context/ErrorTokenLoadingContext";

import InputHeader from "../../components/Input/InputHeader";
import Loader from "../../components/Loader/Loader";
import MachineCard from "../../components/MachineCard/MachineCard";

import httpApi from "../../api/httpApi";

import * as DATE_UTILS from "../../services/date.utils";

const MachineShopDemography = () => {

  const {token, elState, elDispatch} = useErrorTokenLoading();
  
  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate());
  const [currentDate, setCurrentdate] = useState(DATE_UTILS.workingDate());
  const [machinesData, setMachinesData] = useState([]);

  useEffect(() => {
    document.title = "DPS | Demography";
  }, []);

  useEffect(() => {
    const fetchMyAPI = async () => {
      try {
        elDispatch(loading());
        const response = await httpApi.getData(token, '/demography', { currentDate: workingDate });
        //console.log(response);
        setMachinesData(response);
        elDispatch(success());
      } catch (err) {
        elDispatch(failure(err.response))
        //console.error(err.response);
      }
    };
    fetchMyAPI();
  }, [workingDate, token]);

  const dateChangeHandler = (e) => {
    setCurrentdate(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //setWorkingDate(e.target.form.queryDate.value);
    setWorkingDate(currentDate);
  };

  const prevNextHandler = (actionType) => {
    setCurrentdate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
  };

  return (
    <div className="max-container">
      <InputHeader
        date={currentDate}
        header="Demography"
        submitHandler={submitHandler}
        dateChangeHandler={dateChangeHandler}
        prevNextHandler={prevNextHandler}
      />
      <br />
      {elState.loading && <Loader />}
      
      {!elState.loading && (
          <div className="flex-container">
            {machinesData["demographyData"]?.length !== 0 &&
              machinesData["demographyData"]?.map((machine) => (
                <div key={machine["machine"]} className="flex-item-3">
                  <MachineCard
                    value={machine["oee"]}
                    name={machine["machine"]}
                    target={machine["daily_target"]}
                    achieved={machine["achieved_qty"]}
                    rejection={machine["rejection_total"]}
                    idletime={machine["idletime_total"]}
                  />
                </div>
              ))}
          </div>
        )}
    </div>
  );
};

export default MachineShopDemography;
