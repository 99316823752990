import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material'

const DivCloseIcon = styled('div')(({ theme, size }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: size === 'small' ? '18px' : '35px',
  width: size === 'small' ? '18px' : '35px',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,

  '&:hover': {
    backgroundColor: theme.palette.background.hover,
  },
}))

const CloseButton = (props) => {
  const { onClickHandler, size } = props
  return (
    <DivCloseIcon onClick={onClickHandler} size={size}>
      <CloseIcon fontSize={size && '6px'} />
    </DivCloseIcon>
  )
}

export default CloseButton
