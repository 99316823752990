import React from "react";
import { VistaTable } from "vista-table-react";

const RejectionIdleInfo = ({ data, tableTitle }) => {
  const tableHeader = [
    { key: "time_frame", title: "Time Frame" },
    { key: "shift1", title: "Shift-1" },
    { key: "shift2", title: "Shift-2" },
    { key: "total", title: "Total" },
  ];

  const tableEntries = [
    {
      time_frame: "Day",
      shift1: !data["dailyData"]["shift1"] ? data["dailyData"]["shift1"] === 0 ? 0 : 'No Data' : data["dailyData"]["shift1"],
      shift2: !data["dailyData"]["shift2"] ? data["dailyData"]["shift2"] === 0 ? 0 : 'No Data' : data["dailyData"]["shift2"],
      total: !data["dailyData"]["total"] ? data["dailyData"]["total"] === 0 ? 0 : 'No Data' : data["dailyData"]["total"],
    },
    {
      time_frame: "Month",
      shift1: data["monthlyData"]["shift1"],
      shift2: data["monthlyData"]["shift2"],
      total: data["monthlyData"]["total"],
    },
  ];

  return (
    <div className="card-container flex-row border-thin mt-5">
      <div className="card-header bg-grey">
        <h3>{tableTitle}</h3>
        <span className="cam-icons">
          <i
            className="fa fa-camera-retro ico-lg"
            title="Take a snap"
            // onClick={() => takeASnap()}
          ></i>
        </span>
      </div>
      <div className="card-body pd-0">
        <VistaTable headers={tableHeader} data={tableEntries} />
      </div>
    </div>
  );
};

export default RejectionIdleInfo;
