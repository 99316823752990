import React, { useState } from 'react';
import { styled } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'

const SidebarLink = styled("div")(({ theme, isActive }) => ({
  userSelect: 'none',
  height: '50px',
  color: 'black',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '16px',
  cursor: 'pointer',
  padding: '16px',
  borderRadius: '4px',
  backgroundColor: isActive ? theme.palette.primary.contrast : '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.contrast,
    color: theme.palette.primary.main,
  }
}));

const NavTitle = styled("div")(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const SidebarLabel = styled("span")(({ theme }) => ({
  marginLeft: '16px'
}));

const DropdownLink = styled("div")(({ theme, isActive }) => ({
  height: '40px',
  paddingLeft: '24px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'black',
  fontSize: '14px',
  cursor: 'pointer',
  borderRadius: '4px',
  backgroundColor: isActive ? theme.palette.primary.contrast : '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.contrast,
    color: theme.palette.primary.main,
  }
}));

const SubMenu = ({ item }) => {

  // hooks
  const navigate = useNavigate()
  const location = useLocation()

  // component state
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink
        isActive={item.path === location.pathname && !item.subNav}
        onClick={() => {
          if (item.subNav) {
            showSubnav()
          }
          if (!item.subNav) {
            navigate(item.path)
          }
        }}>
        <NavTitle>
          {item.renderIcon()}
          <SidebarLabel>{item.title}</SidebarLabel>
        </NavTitle>
        <div>
          {item.subNav && subnav
            ? item.iconOpened()
            : item.subNav
              ? item.iconClosed()
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((subItem, index) => {
          return (
            <DropdownLink
              isActive={location.pathname === subItem.path}
              onClick={() => navigate(subItem.path)}
              key={index}
            >
              {subItem.renderIcon()}
              <SidebarLabel>{subItem.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;