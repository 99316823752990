import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ScrollButton from '../components/scrollBtn/ScrollBtn'
import ErrorComp from '../components/Error/ErrorComp'
import MachineDescription from '../components/MachineDescription/MachineDescription'

import { useErrorTokenLoading } from '../context/ErrorTokenLoadingContext'

import styles from './Layout.module.css'

const Layout = () => {
  
  const { elState } = useErrorTokenLoading()
  const errorMessage = `${elState.error?.status} ${elState.error?.statusText}`

  return (
    <div>
      <Header />
      <MachineDescription />
      <div className={styles.page}>
        {elState.error?.status && <ErrorComp message={errorMessage} />}
        <Outlet />
      </div>
      <ScrollButton />
      <Footer />
    </div>
  )
}

export default Layout
