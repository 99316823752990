import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import StyledTable from '../../components_u4/ReactTable/StyledTable'
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'
import EmployeeUploadModal from './EmployeeUploadModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

import {
  useErrorTokenLoading,
  loading,
  failure,
  success,
} from '../../context/ErrorTokenLoadingContext'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//services
import employeeService from '../../services/employee.service'

//Utils
import { showNotification } from '../../utils/toast.utils'
import { ROLES } from '../../utils/constant.utils'

const EmployeeManagement = () => {
  const { token } = useErrorTokenLoading()

  const defaultDesignation = {
    value: 0,
    label: 'Select designation',
  }

  const [employeeId, setEmployeeId] = useState('')
  const [employeeName, setEmployeeName] = useState('')
  const [employeeRole, setEmployeeRole] = useState(defaultDesignation)

  const [employees, setEmployees] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [openAddEmployeeModal, setOpenAddEmployeeModal] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const fetchEmployees = async () => {
    const data = {}

    if (employeeId !== '') {
      data.employeeId = employeeId.trim()
    }

    if (employeeName !== '') {
      data.employeeName = employeeName.trim()
    }

    if (employeeRole.value !== 0) {
      data.employeeRole = employeeRole.value
    }

    try {
      setIsLoading(true)
      const employeesResponse = await employeeService.getEmployees(data, token)
      const employeesData = employeesResponse.data.map((employee, i) => {
        return {
          index: i + 1,
          id: employee.id,
          name: employee.name,
          role: employee.role,
          createdBy: employee.createdBy,
        }
      })
      setEmployees(employeesData)
    } catch (err) {
      console.log(err)
      setEmployees([])
    } finally {
      setIsLoading(false)
    }
  }

  const addEmployee = async (employeeData) => {
    const { employeeId, employeeName, employeeRole } = employeeData

    if (!employeeId) {
      showNotification('Employee id is required', 'error')
      return
    }

    if (!employeeName) {
      showNotification('Employee name is required', 'error')
      return
    }

    if (employeeRole === 0) {
      showNotification('Employee designation is required', 'error')
      return
    }

    const data = {
      id: employeeId,
      name: employeeName,
      role: employeeRole,
      createdBy: 'user1',
    }

    try {
      setIsLoading(true)
      const employeeResponse = await employeeService.addEmployee(data, token)
      console.log(employeeResponse)
      if (employeeResponse.success) {
        showNotification(`Employee added successfully`, 'success')
      } else {
        showNotification(employeeResponse.data.message, 'error')
      }
      setOpenAddEmployeeModal(false)
      fetchEmployees()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const editEmployee = async (employeeData) => {
    const { employeeId, employeeName, employeeRole } = employeeData

    if (!employeeId) {
      showNotification('Employee id is required', 'error')
      return
    }

    if (!employeeName) {
      showNotification('Employee name is required', 'error')
      return
    }

    if (employeeRole === 0) {
      showNotification('Employee designation is required', 'error')
      return
    }

    const data = {
      name: employeeName,
      role: employeeRole,
      updatedBy: 'user1',
    }

    try {
      setIsLoading(true)
      const employeeResponse = await employeeService.editEmployee(
        employeeId,
        data,
        token
      )
      console.log(employeeResponse)
      if (employeeResponse.success) {
        showNotification(`Employee updated successfully`, 'success')
      } else {
        showNotification(employeeResponse.data.message, 'error')
      }
      setOpenAddEmployeeModal(false)
      fetchEmployees()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteEmployee = async (employeeId) => {
    if (!employeeId) {
      showNotification('Employee id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const employeeResponse = await employeeService.deleteEmployee(
        employeeId,
        token
      )
      if (employeeResponse.data.success) {
        showNotification(`Employee deleted successfully`, 'success')
      } else {
        showNotification(employeeResponse.data.message, 'error')
      }
      fetchEmployees()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const clearAll = async () => {
    setEmployeeName('')
    setEmployeeId('')
    setEmployeeRole(defaultDesignation)
    await fetchEmployees()
  }

  useEffect(() => {
    if (employeeRole === null) {
      return
    }
    fetchEmployees()
  }, [employeeRole])

  const employeeColumns = [
    {
      Header: 'S.No',
      accessor: 'index',
    },
    {
      Header: 'Emp ID',
      accessor: 'id',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Designation',
      accessor: 'role',
    },
    {
      Header: 'Created by',
      accessor: 'createdBy',
    },
    {
      Header: 'Edit',
      Cell: ({ cell: { row } }) => {
        return (
          <EditIcon
            onClickHandler={() => {
              setIsEditMode(true)
              setSelectedEmployee({ ...row.values })
              setOpenAddEmployeeModal(true)
            }}
          />
        )
      },
    },
    {
      Header: 'Delete',
      Cell: ({ cell: { row } }) => {
        return (
          <DeleteIcon
            onClickHandler={() => {
              setOpenDeleteModal(true)
              setSelectedEmployee({ ...row.values })
            }}
          />
        )
      },
    },
  ]

  if (isLoading) {
    return <div>Loading.....</div>
  }

  return (
    <StyledPageContainer>
      <StyledPageHeader title='Manage employees' />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-between',
          alignItems: 'center',
          columnGap: '16px',
          padding: '16px 0',
          color: 'black'
        }}
      >
        <div>
          <Button
            variant='contained'
            onClick={() => {
              setIsEditMode(false)
              setSelectedEmployee({})
              setOpenAddEmployeeModal(true)
            }}
          >
            Add employee
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-between',
            marginLeft: 'auto',
            columnGap: '16px',
            padding: '16px 0',
          }}
        >
          <Button
            startIcon={<ClearIcon />}
            variant='contained'
            onClick={clearAll}
          >
            Clear
          </Button>
          <Button
            startIcon={<SearchIcon />}
            variant='contained'
            onClick={fetchEmployees}
          >
            Search
          </Button>
        </div>
      </div>
      <Stack
        spacing={2}
        direction='row'
        sx={{ marginBottom: '16px', width: '100%', alignItems: 'center' }}
      >
        <StyledTextFieldWithLabel
          label='Employee id'
          placeholderText='Enter employee id..'
          textValue={employeeId}
          onChangeHandler={(e) => setEmployeeId(e.target.value)}
          isDisabled={false}
          type='text'
        />
        <StyledTextFieldWithLabel
          label='Employee name'
          placeholderText='Enter employee name..'
          textValue={employeeName}
          onChangeHandler={(e) => setEmployeeName(e.target.value)}
          isDisabled={false}
          type='text'
        />

        <StyledSelectField
          label='Designation'
          placeHolderText='Select designation'
          selectedValue={employeeRole}
          selectOptions={ROLES}
          onChangeHandler={(value) => setEmployeeRole(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage='No designation found'
        />
      </Stack>
      <StyledTableContainer>
        {!isLoading && employees.length === 0 && (
          <StyledEmptyHeader>There are no employees</StyledEmptyHeader>
        )}
        {employees.length > 0 && (
          <StyledTable
            pagination={true}
            columns={employeeColumns}
            data={employees}
            hiddenColumns={[]}
          />
        )}
      </StyledTableContainer>
      {openAddEmployeeModal && (
        <EmployeeUploadModal
          closeHandler={() => setOpenAddEmployeeModal(false)}
          isModalOpen={openAddEmployeeModal}
          addEmployee={addEmployee}
          editEmployee={editEmployee}
          isEditMode={isEditMode}
          selectedEmployee={selectedEmployee}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to delete the following employee ${selectedEmployee.id}_${selectedEmployee.name}`}
          buttonTitle='Delete'
          clickHandler={() => deleteEmployee(selectedEmployee.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </StyledPageContainer>
  )
}

export default EmployeeManagement
