
export const OP_REASONS = {
    OP110: {
      id_os: "Setting rejection",
      id_poor_finish: "Casting defect",
      id_unwash: "ID unwash",
      id_blow_hole: "ID blowhole/dent/crack",
      width_us_os: "ID poor finish",
      id_perpendicularity_issue: "ID u/s&o/s",
      more_chamfer: "Power failure",
      less_chamfer: "Chamfer u/s&o/s",
      power_failure: "Dent&damage",
      settings_rejection: "Others",
    },
    
    OP115:{
        id_os:  "id os",
        id_poor_finish :"id poor finish",
        id_unwash:"id unwash",
        id_blow_hole: "id_blow_hole",
        width_us_os: "width_us_os",
        id_perpendicularity_issue:"id_perpendicularity_issue",
        more_chamfer: "more_chamfer",
        less_chamfer: "less_chamfer",
        power_failure:  "power_failure",
        settings_rejection: "settings_rejection",
    },
  
    OP130: {
      id_os: "Setting rejection",
      id_poor_finish: "Od_linemark /poor finish",
      id_unwash: "Od unwash",
      id_blow_hole: "Blowhole & Edge break",
      width_us_os: "Groove depth o/s&u/s",
      id_perpendicularity_issue: "Groove depth parallelism not ok",
      more_chamfer: "Od u/s&o/s",
      less_chamfer: "Chamfer u/s&o/s",
      power_failure: "Od taper",
      settings_rejection: "Dent & Damage",
    },
  
    OP140: {
      id_os: "Height us os",
      id_poor_finish: "Taper",
      id_unwash: "Drilling depth",
      id_blow_hole: "Poor surface finish",
      width_us_os: "Drill dia not ok", //drill dia not ok for op140
      id_perpendicularity_issue: "Dia2 not ok",
      more_chamfer: "Depth us os",
      less_chamfer: "Blowhole",
      power_failure: "Power failure",
      settings_rejection: "Face unwash",
    },
  };
  

  