import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
  color: #424242;
`

export const Logo = styled.div`
  margin-top: 24px;
  max-width: 100%;
`

export const Profile = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`

export const MainHeading = styled.h1``

export const CardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 32px;
  padding: 16px 0;
`

export const Card = styled.div`
  position: relative;
  width: 300px;
  height: 100px;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  &:hover {
    background-color: #fafafa;
  }
`
