import React from "react";
import classes from './NoData.module.css';

const NoData = () => {
  return (
    <div className={`${classes.positionCenter} flex-column`}>
      <i className={`fa fa-frown-o ${classes.noDataIcon}`}></i>
      <p className={classes.description}>
        Oh No!! I don't find any Data for selected Date...
      </p>
    </div>
  );
};

export default NoData;
