export const calculateKpiParameters = ({
  totalTime = 720,
  availableTime = 650,
  shiftTarget,
  powerCut,
  partCount,
  rejectionCount,
}) => {
  // console.log("KPI_DATA", {
  //   totalTime,
  //   availableTime,
  //   shiftTarget,
  //   powerCut,
  //   partCount,
  //   rejectionCount,
  // });
  //get the actual time
  const actualTime = availableTime - powerCut;

  // calculate the availability
  const availability = Math.floor((actualTime / totalTime) * 100);

  // calculate the accepted count
  let acceptCount = 0;
  if (partCount > rejectionCount) {
    acceptCount = partCount - rejectionCount;
  }

  // calculate the quality
  let quality = 0;
  if (partCount && partCount > 0) {
    quality = Math.floor((acceptCount * 100) / partCount);
  }

  // calculate the performance
  let performance = 0;
  if (partCount && shiftTarget && shiftTarget > 0) {
    performance = Math.floor((partCount * 100) / shiftTarget);
  }

  // calculate the oee
  const OEE = (availability * performance * quality) / 10000;

  return OEE;
};
