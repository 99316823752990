import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";

//Routes config
import { routes } from './routes.js';


//styles and assets
import logo from "../../assets/logo.png";
import classes from './Header.module.css';

const Header = () => {

  const navigate = useNavigate()
  const { logout, user } = useAuth0();

  //console.log("user", user);
  const avatarImage = user.picture? user.picture : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9I_MQt-XaARCioxfmkE94ipJHJpGEhdUQ7-wWot1sT3keKbUCI4IMiqmjdZg29Dfh-iM&usqp=CAU";

  const activeStyle = {
    fontWeight: "bold",
    borderRadius: "8px 8px 0 0",
    backgroundColor: '#c0c1c4',
    backgroundImage: 'linear-gradient(to bottom, #c0c1c4, #f5f7fa)',
    transition: "0.2s ease-out",
  };

  return (
    <div className={classes.topNavbar}>
      <div className={classes.logo} onClick={()=> navigate('/')}>
        <img alt="logo" src={logo} />
      </div>
      <div className={classes.navItems}>
        <div className={classes.liveData}>
          <h4>LIVE DATA</h4>
          <div className={classes.liveDataLinks}>
            <div className={classes.liveDataLink}>
              <a
                href="https://www.corvinacloud.com/manage/access/connections/standalone"
                target="_blank"
                rel="noreferrer"
              >
                UNIT-1
              </a>
            </div>
            <div className={classes.liveDataLink}>
              <a
                href="https://www.corvinacloud.com/manage/access/connections/standalone"
                target="_blank"
                rel="noreferrer"
              >
                UNIT-2
              </a>
            </div>
          </div>
        </div>
        {routes.map((item) => (
          <NavLink
            className={classes.navItem}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            key={item.path}
            to={item.path}
          >
            {item.text}
          </NavLink>
        ))}
      </div>
      <div
        style={{
          minWidth: "150px",
          marginRight: "1.5rem",
          marginTop: "0.5rem",
        }}
      >
        <div
          className={`${classes.avatarHolder} flex-container`}
          style={{ flexWrap: "nowrap" }}
        >
          <img className= {`${classes.avatar} shadow`} alt="avatar" src={avatarImage} />
          <label className={classes.avatarLabel}>{user.nickname}</label>
          <div className={classes.dropdownContent}>
            <a href="/#" onClick={(e) => e.preventDefault()}>
              <i className="fa fa-user-o" aria-hidden="true"></i>
              {user.nickname}
            </a>
            <a href="/#" onClick={(e) => e.preventDefault()}>
              <i className="fa fa-envelope-o" aria-hidden="true"></i>
              {user.name}
            </a>
            <hr />
            <a
              href="/#"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              <i className="fa fa-sign-out" aria-hidden="true"></i> Log out
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
