import React from 'react'
import { styled } from '@mui/material'
import Modal from '@mui/material/Modal'

import CloseButton from '../CloseButton/CloseButton'
import LoadingSpinner from '../LoadingSpinner'

const DivModalBody = styled('div')(
  ({ theme, modalWidth, modalHeight, isDialog }) => ({
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: modalWidth,
    height: modalHeight,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    padding: isDialog ? '24px' : '32px',
    borderRadius: '16px',
  })
)

const DivTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const TypographyModalTitle = styled('h4')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '20px',
}))

const StyledModal = (props) => {
  const {
    isModalOpen,
    modalTitle,
    modalCloseHandler,
    children,
    loaderState,
    modalWidth,
    modalHeight,
    isDialog,
  } = props

  return (
    <Modal disableEnforceFocus disableAutoFocus open={isModalOpen}>
      <DivModalBody
        modalWidth={modalWidth}
        modalHeight={modalHeight}
        isDialog={isDialog}
      >
        <DivTitleContainer>
          <TypographyModalTitle>{modalTitle}</TypographyModalTitle>
          <CloseButton onClickHandler={() => modalCloseHandler()} />
        </DivTitleContainer>
        {children}
        <LoadingSpinner loading={loaderState} />
      </DivModalBody>
    </Modal>
  )
}

export default StyledModal
