import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'

//service
import hmiUpdateService from '../../services/hmiUpdate.service'

//context
import { showNotification } from '../../utils/toast.utils'

const hmiParameters = [
  {
    name: 'Update employee name',
    parameter: 'Employee name',
    path: '/hmi/employees',
    params: {
      parameter: 'operator',
      columnName: 'name',
    },
  },
  {
    name: 'Update employee Id',
    parameter: 'Employee Id',
    path: '/hmi/employees',
    params: {
      parameter: 'operatorId',
      columnName: 'id',
    },
  },
  {
    name: 'Update line inspector',
    parameter: 'line inspector',
    path: '/hmi/employees',
    params: {
      parameter: 'lineInspector',
      columnName: 'name',
    },
  },
  {
    name: 'Update supervisor',
    parameter: 'supervisor',
    path: '/hmi/employees',
    params: {
      parameter: 'supervisor',
      columnName: 'name',
    },
  },
  {
    name: 'Update part number',
    parameter: 'part name',
    path: '/hmi/parts',
    params: {
      parameter: 'partName',
      columnName: 'name',
    },
  },
  {
    name: 'Update operation type',
    parameter: 'part type',
    path: '/hmi/parts',
    params: {
      parameter: 'partType',
      columnName: 'type',
    },
  },
]

const CardContainer = styled('div')(({ theme }) => ({
  marginTop: '24px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  overflowY: 'scroll',
  //height: '100%', //comment because, wrap was having not proper space when it pushed to next line
}))

const Card = styled('div')(({ theme, isSelected }) => ({
  position: 'relative',
  width: '300px',
  height: '100px',
  display: 'flex',
  justifyContent: 'Center',
  alignItems: 'center',
  padding: '8px 16px',
  backgroundColor: isSelected ? '#ccccd7' : '#fff',
  color: '#000',
  borderRadius: '8px',
  cursor: 'pointer',
  boxShadow: '0 8px 28px 0 rgb(18 18 19 / 10%)',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: '#ccccd7',
    color: 'black',
  },
}))

const Title = styled('div')(({}) => ({
  textAlign: 'center',
  overflow: 'hidden', //Don't remove
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

const LoadingContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  rowGap: '16px',
  fontWeight: 600,
}))

const UpdateHmiParameters = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentParameter, setCurrentParameter] = useState(false)

  const updateHmiParameter = async (path, parameter, params) => {
    showNotification(`Available only in local version`, 'info')
    return
    try {
      setIsLoading(true)
      setCurrentParameter(parameter)
      const updateResponse = await hmiUpdateService.updateHmiParameter(
        path,
        params
      )
      if (updateResponse.success) {
        showNotification(`updated in ${parameter} HMI successfully`, 'success')
      } else {
        showNotification(updateResponse.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
        <p>Updating {currentParameter} ....</p>
      </LoadingContainer>
    )
  }

  return (
    <StyledPageContainer>
      <StyledPageHeader title='Update HMI parameters' />
      <CardContainer>
        {hmiParameters &&
          React.Children.toArray(
            hmiParameters.map((parameter) => (
              <Card
                onClick={() =>
                  updateHmiParameter(
                    parameter.path,
                    parameter.parameter,
                    parameter.params
                  )
                }
              >
                <Title>{parameter.name}</Title>
              </Card>
            ))
          )}
      </CardContainer>
    </StyledPageContainer>
  )
}

export default UpdateHmiParameters
