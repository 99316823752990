import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'

//components
import RippleEffect from '../../../components/RippleEffect/RippleEffect'

//utils
import { capitalizeFirstLetter } from '../../../utils/string.utils'
import { showNotification } from '../../../utils/toast.utils'

//styles and assets
import Machine from '../../../assets/machine-1.png'

const data1 = [
  {
    index: 0,
    machine: 'Machine1',
    name: 'PART_NAME',
    type: 'PART_TYPE',
    partCount: 'S_PART_COUNT',
    rejectionCount: 'S_REJ_COUNT',
    machineStatus: 'MC_STATUS',
    operator: 'OPERATOR_NAME',
  },
  {
    index: 1,
    machine: 'Machine2',
    name: 'PART_NAME',
    type: 'PART_TYPE',
    partCount: 'S_PART_COUNT',
    rejectionCount: 'S_REJ_COUNT',
    machineStatus: 'MC_STATUS',
    operator: 'OPERATOR_NAME',
  },
]

const Detail = ({ label, value }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '16px',
        fontWeight: 500,
      }}
    >
      <p>{label}</p>
      <div
        style={{
          fontWeight: 700,
        }}
      >
        {value}
      </div>
    </div>
  )
}

const CardContainer = styled('div')(({ theme }) => ({
  marginTop: '16px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '32px',
  overflowY: 'scroll',
  //height: '100%', //comment because, wrap was having not proper space when it pushed to next line
}))

const Card = styled('div')(({ theme, isSelected }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  //justifyContent: "flex-start",
  alignItems: 'center',
  rowGap: '8px',
  padding: '16px',
  overflow: 'hidden',
  backgroundColor: isSelected ? '#ccccd7' : '#fff',
  color: '#000',
  borderRadius: '8px',
  cursor: 'pointer',
  boxShadow: '0 8px 28px 0 rgb(18 18 19 / 10%)',
  textAlign: 'center',
  '&:hover': {},
}))

const Card1 = styled('div')(({ isActive }) => ({
  width: '40%',
  padding: '4px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: '16px',
  backgroundColor: !isActive ? 'red' : 'transparent',
  '&:hover': {
    backgroundColor: '#ccccd7',
    color: 'black',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '160px',
    height: '600%',
    backgroundImage: isActive
      ? 'conic-gradient(lightgreen, lightgreen)'
      : 'conic-gradient(transparent, transparent)',
    animation: 'breatheAnimation 4s linear infinite',
  },
  '@keyframes breatheAnimation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(350deg)',
    },
  },
}))

export const MachineImage = styled('img')(() => ({
  maxWidth: '100%',
}))

const LiveDataGrid = (props) => {
  //hooks
  const navigate = useNavigate()

  //component state
  const [machineData, setMachineData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchMachineStatus = async () => {
      try {
        //setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/unit-4/live-data`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        )
        console.log(response)
        if (response.data.success) {
          const machineWiseData = Object.values(response.data.data)
          const data = machineWiseData
            .filter((machine) => (machine && machine.machine ? true : false))
            .map((machine, i) => {
              return {
                index: i + 1,
                machine: capitalizeFirstLetter(machine.machine),
                name: machine.partName,
                type: machine.partType,
                partCount: machine.partCount,
                rejectionCount: machine.rejectionCount,
                machineStatus: machine.machineStatus,
                operator: machine.operator,
                //idleTime: machine.
              }
            })
          setMachineData(data)
          //showNotification(`Machine status fetched successfully`, "success");
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        //setIsLoading(false);
      }
    }
    fetchMachineStatus()
    const timeId = setInterval(() => {
      try {
        fetchMachineStatus()
      } catch (error) {
        console.log(error)
      }
    }, 10 * 1000)

    return () => {
      clearInterval(timeId)
    }
  }, [])

  if (isLoading) {
    return <>Loading....</>
  }

  return (
    <CardContainer>
      {machineData.length > 0 &&
        React.Children.toArray(
          machineData.map((machine) => (
            <Card1
              isActive={
                machine.machineStatus === true || machine.machineStatus === 1
                  ? false
                  : true
              }
            >
              <Card
                onClick={() =>
                  navigate(
                    `/unit-4/machine-wise?tab=overview&machine=${machine.machine}`
                  )
                }
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <p style={{ alignSelf: 'flex-start' }}>
                    <strong>{machine.machine}</strong>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '4px',
                    }}
                  >
                    <ManageAccountsIcon fontSize='small' />
                    <p>{machine.operator}</p>
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '30%' }}>
                    <MachineImage src={Machine} alt='company logo' />
                  </div>
                  <div
                    style={{
                      width: '70%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      rowGap: '4px',
                    }}
                  >
                    {/* <p>
                      status:{" "}
                      <strong>
                        {machine.machineStatus === true ||
                        machine.machineStatus === 1
                          ? 1
                          : 0}
                      </strong>
                    </p> */}
                    <Detail label='Part' value={machine.name} />
                    <Detail label='Operation' value={machine.type} />
                    <Detail label='Count' value={machine.partCount} />
                    <Detail label='Rejection' value={machine.rejectionCount} />
                  </div>
                </div>
              </Card>
            </Card1>
          ))
        )}
    </CardContainer>
  )
}

export default LiveDataGrid
