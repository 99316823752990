import React from "react";
import logo from "../../assets/feature_logo.png";

import classes from "./MachineDescription.module.css";

const MachineDescription = () => {
  return (
    <div className={classes.machineDes}>
      <div className={classes.machineTobe}>
        <img alt="logo" src={logo} />
      </div>
      <div className={classes.content}>
        <h5
          style={{
            minWidth: "fit-content",
            display: "flex",
            justifyContent: "start",
            margin: "0",
            color: "black",
            borderBottom: "1px solid black",
          }}
        >
          MY MACHINE MONITORS :
        </h5>
        <div className={classes.machineFeatures}>
          <div className={classes.machineFeatureItem}>
            <i className="fa fa-industry" aria-hidden="true"></i>
            <h5>MACHINE SHOPS</h5>
          </div>
          <div className={classes.machineFeatureItem}>
            <i className="fa fa-cogs" aria-hidden="true"></i>
            <h5>MACHINES</h5>
          </div>
          <div className={classes.machineFeatureItem}>
            <i className="fa fa-user" aria-hidden="true"></i>
            <h5>OPERATOR PERFORMANCE</h5>
          </div>
          <div className={classes.machineFeatureItem}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
            <h5>REJECTION & REASONS</h5>
          </div>
          <div className={classes.machineFeatureItem}>
            <i className="fa fa-hourglass-start" aria-hidden="true"></i>
            <h5>IDLETIME & REASONS</h5>
          </div>
          <div className={classes.machineFeatureItem}>
            <i className="fa fa-percent" aria-hidden="true"></i>
            <h5>OEE</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineDescription;
