import React from "react";
import { Doughnut } from "react-chartjs-2";
import * as CHART_UTILS from "./Doughnut.constant";

const DoughnutChart = ({ /*chartTitle,*/ chartLabels, dataGroup }) => {
  const myChartRef = React.createRef();

  const doughnutChart = dataGroup ? (
    <Doughnut
    ref={myChartRef}
      data={{
        labels: chartLabels,
        datasets: [
          {
            ...CHART_UTILS.DOUGHNUT,
            data: dataGroup,
          },
        ],
      }}
      height={350}
      width={400}
      options={{
        maintainAspectRatio: false,
        plugins: {
          title: {
            ...CHART_UTILS.TITLE,
            //text: `${chartTitle}`,
          },
          tooltip: CHART_UTILS.TOOTIP,
          legend: {
            ...CHART_UTILS.LEGEND,
          },
        },
      }}
    />
  ) : null;

  return (
    <div className="flex-container ">{doughnutChart}</div>
  ) 
};

export default DoughnutChart;
