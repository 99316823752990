const { styled, Drawer, List } = require('@mui/material')

const drawerWidth = 250

export const StyledDrawer = styled(Drawer)(({ theme }) => {
  const styles = {
    backgroundColor: theme.palette.background.default,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }

  return {
    width: drawerWidth,
    display: 'flex',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...styles,
    '& .MuiDrawer-paper': {
      ...styles,
    },
  }
})

export const DivRoot = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  '& .MuiListItem-gutters': {
    paddingLeft: 0,
  },

  '& .Mui-selected': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },

  '& .Mui-selected:hover': {
    backgroundColor: theme.palette.secondary.light,
  },

  '& .MuiListItem-button:hover': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.dark,
    },
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.dark,
  },

  '& .MuiListItem-divider': {
    border: 'none',
  },
}))

export const StyeldDrawer = styled(Drawer)(({ theme, open }) => {
  const openStyles = {
    backgroundColor: theme.palette.background.default,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }
  const closedStyles = {
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(12) + 1,
    },
  }

  return {
    width: drawerWidth,
    display: 'flex',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open ? openStyles : closedStyles),
    '& .MuiDrawer-paper': {
      ...(open ? openStyles : closedStyles),
    },
  }
})

export const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  height: '100%',
  overflowY: 'auto',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 0px 9px 0px rgb(0 0 0 / 10%)',
}))

export const DivToolbar = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: '24px',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

export const DivDrawerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  padding: '16px 24px',
}))

export const StyledList = styled(List)(({ theme }) => ({
  height: '100%',
}))

export const DivLogoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0 16px',
  marginTop: '16px',
}))

export const ImgLogo = styled('img')(({ theme }) => ({
  maxWidth: '100%',
}))

export const DivNavBox = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  marginTop: '12px',
}))

export const DivProgressContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 'auto',
  height: 'auto',
  right: 0,
  bottom: 0,
  margin: '1rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',

  //border: "2px solid blue",
  justifyContent: 'center',

  alignItems: 'center',
}))

export const DocLinks = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  marginTop: '12px',
  bottom: '16px',
  marginTop: 'auto',
  paddingLeft: '8px',
}))

export const ListItemIcon = styled('div')(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  fontWeight: 'normal',
  minWidth: '40px',
  marginLeft: open ? '8px' : '22px',
  flexDirection: open ? 'row' : 'column',
}))

export const ListItemText = styled('div')(({ theme, open }) => ({
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  // fontSize: "1rem"
  display: open ? '' : 'none',
}))

export const StyledNavLink = styled('div')(({ theme, isActive }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '16px',
  fontSize: ' 16px',
  lineHeight: '16px',
  fontWeight: 500,
  color: theme.palette.primary.main,
  borderRadius: '16px',
  backgroundColor: isActive ? theme.palette.primary.contrast : '',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: isActive ? '' : theme.palette.primary.contrast,
    color: isActive ? '' : theme.palette.primary.main,
  },
}))

export const Slider = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  color: theme.palette.primary.dark,
  top: 6,
  left: -15,
  borderRadius: '50%',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.hover,
  },
}))

export const ProfileContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  top: '30px',
  right: '50px',
  borderRadius: '50%',
  cursor: 'pointer',
}))

export const NavTitle = styled('div')(({ theme }) => ({
  marginLeft: '0rem',
  color: theme.palette.primary.light,
  marginBottom: '0.5rem',
}))

export const NavTitleClose = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '16px',
  color: theme.palette.primary.light,
}))
