import React from "react";
import classes from './Footer.module.css';

const Footer = () => {
    const d = new Date();
    const n = d.getFullYear();
    return (
        <footer className={classes.footer}>
            <p>Copyright @{n}
                <span style={{float: "right  "}}> Developed by &nbsp;
                    <a href="mailto:subhashgn1994@gmail.com" style={{textDecoration: "none"}}>Coderz Po<i className="fa fa-podcast" aria-hidden="true"></i>nt</a>
                </span>
            </p>
        </footer>
    );
}

export default Footer;