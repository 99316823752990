import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const DivInputField = styled("div")(({ theme, enableMargin }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  margin: enableMargin ? '8px  0 24px 0' : 0
}));

const DivInputLabelBox = styled("div")(({ theme,  }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "8px",
}));

const DivInputBox = styled("div")(({ theme }) => ({
  width: "100%",
}));

const TypographyInputLabel = styled(Typography)(({ theme }) => ({
  display: "block",
  color: theme.palette.primary.main,
  fontWeight: 500,
}));

const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  minHeight: "46px",
  marginBottom: "0px",
  padding: "0 24px",
  border: "1px solid #a8a8b5",
  borderRadius: "8px",

  //boxShadow: '0 2px 7px 0 rgb(18 18 19 / 6%)',
  transition:
    "box-shadow 300ms ease, color 300ms ease, border-color 300ms ease",

  fontSize: "14px",
  lineHeight: " 20px",

  "&:hover": {
    borderColor: "#a8a8b5",
    boxShadow: "0 2px 12px 0 rgb(18 18 19 / 10%)",
  },

  "&:focus": {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    outline: 0,
  },

  sup: {
    fontSize: "16px",
  },
}));

const FormTextField = (props) => {
  let {
    label,
    placeholderText,
    textValue,
    onChangeHandler,
    inputType,
    onBlurHandler,
    isRequired = false,
    isDisabled,
    autoFocus,
    enableMargin
  } = props;

  let defaultInputType = "text";
  let defaultRequiredValue = false;

  if (inputType !== undefined) {
    defaultInputType = inputType;
  }

  if (isRequired !== undefined) {
    defaultRequiredValue = isRequired;
  }

  if (onBlurHandler === undefined) {
    onBlurHandler = () => {};
  }

  if (onChangeHandler === undefined) {
    onChangeHandler = () => {};
  }

  if (isDisabled === undefined) {
    isDisabled = false;
  }

  return (
    <DivInputField enableMargin={enableMargin}>
      <DivInputLabelBox>
        <TypographyInputLabel variant="h5">
          {label} {isRequired && <sup style={{ fontSize: "16px" }}>*</sup>}
        </TypographyInputLabel>
      </DivInputLabelBox>
      <DivInputBox>
        <StyledInput
          autoFocus={autoFocus ? autoFocus : false}
          placeholder={placeholderText}
          value={textValue}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          type={defaultInputType}
          required={defaultRequiredValue}
          spellcheck="false"
          disabled={isDisabled}
        />
      </DivInputBox>
    </DivInputField>
  );
};

export default FormTextField;
