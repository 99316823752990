import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  useErrorTokenLoading,
  loading,
  failure,
  success,
} from '../../context/ErrorTokenLoadingContext'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'
import PartWise from './PartWise'
import MachineWise from './MachineWise'

const pageTabs = [
  { name: 'Part-wise', value: 'partWise' },
  { name: 'Machine-wise', value: 'machineWise' },
]

const TabsContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '24px',
  backgroundColor: theme.palette.background.paper,
  padding: '8px 24px 0 0',
  marginBottom: '16px',
  marginTop: '32px',
}))

const Tab = styled('div')(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '16px',
  minWidth: 'fit-content',
  padding: '8px 0px',
  color: isActive ? theme.palette.primary.main : '#ccccd7',
  cursor: 'pointer',
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : '1px solid transparent',
  '&:hover': {
    color: `${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}))

const Dashboard = () => {
  //default values
  let defaultTab = pageTabs[0].value

  const { token } = useErrorTokenLoading()

  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //extract category from URL
  const params = new URLSearchParams(location.search)
  const tab = params.get('tab')

  if (tab) {
    defaultTab = tab
  }

  //component state
  const [activeSubTab, setActiveSubTab] = useState(defaultTab)

  useEffect(() => {
    setActiveSubTab(defaultTab)
  }, [defaultTab])

  const handleSubTabChange = (tab) => {
    navigate(`?tab=${tab}`)
  }

  return (
    <StyledPageContainer>
      <StyledPageHeader title='Dashboard' />
      <TabsContainer>
        {React.Children.toArray(
          pageTabs.map((tab, i) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      {activeSubTab === 'partWise' && <PartWise token={token} />}
      {activeSubTab === 'machineWise' && <MachineWise token={token} />}
    </StyledPageContainer>
  )
}

export default Dashboard
