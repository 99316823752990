import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { StyledEngineProvider } from '@mui/material'

import ErrorTokenLoadingContext from './context/ErrorTokenLoadingContext'

import 'font-awesome/css/font-awesome.min.css'
import './index.css'
import App from './App'

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE
const auth0Scope = process.env.REACT_APP_AUTH0_SCOPE

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
      audience={auth0Audience}
      scope={auth0Scope}
    >
      <Router>
        <StyledEngineProvider injectFirst>
          <ErrorTokenLoadingContext>
            <App />
          </ErrorTokenLoadingContext>
        </StyledEngineProvider>
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
