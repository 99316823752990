import axios from './axios'

const employeeService = {
  getEmployees: async (data, token) => {
    try {
      const response = await axios.get('/unit-4/employees', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...data,
        },
      })

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addEmployee: async (data, token) => {
    try {
      const response = await axios.post('/unit-4/employees', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  editEmployee: async (id, data, token) => {
    try {
      const response = await axios.patch(`/unit-4/employees/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteEmployee: async (id, token) => {
    try {
      const response = await axios.delete(`/unit-4/employees/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default employeeService
