import axios from './axios'

const partService = {
  getParts: async (data, token) => {
    try {
      const response = await axios.get('/unit-4/parts', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...data,
        },
      })

      return response.data
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addPart: async (data, token) => {
    try {
      const response = await axios.post('/unit-4/parts', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  editPart: async (id, data, token) => {
    try {
      const response = await axios.patch(`/unit-4/parts/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deletePart: async (id, data, token) => {
    try {
      const response = await axios.delete(`/unit-4/parts/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  }
}

export default partService
