import React from "react";
import { Bar } from "react-chartjs-2";
import * as CHART_UTILS from "./BarChart.constant";
import * as html2canvas from 'html2canvas';
import Loader from "../../Loader/Loader";

// https://www.chartjs.org/chartjs-plugin-annotation/guide/types/line.html#configuration

// import {Chart} from 'chart.js';
// import  ChartAnnotation from 'chartjs-plugin-annotation';
// Chart.register(ChartAnnotation);

const BarChart = (props) => {

  const {chartTitle, chartLabels , dataGroup, iconClass, yLabel}  = props;

  const takeASnap = () => {
    let screenshotDiv = document.querySelector('#chart-' + chartTitle +' .card-body');
    html2canvas(screenshotDiv).then((canvas) => {
      var a = document.createElement('a');
      a.href = canvas.toDataURL('image/png').replace("image/jpeg", "image/octet-stream");
      a.download = 'somefilename.jpg';
      a.click();
  });
  };

  const barChart = dataGroup ? (
    <Bar
      data={{
        labels: chartLabels,
        datasets: dataGroup
      }}
      height={300}
      width={900}
      options={{
        plugins: {
          // title: { display: true, text: `${chartTitle}` },
          tooltip: CHART_UTILS.TOOLTIP,
          legend: CHART_UTILS.LEGEND,
          // annotation: {
          //   annotations: [{
          //     type: 'line',
          //     yMin: 60,
          //     yMax: 60,
          //     borderColor: 'rgb(0, 0, 0)',
          //     borderWidth: 1,
          //     label: {
          //       enabled: true,
          //       position: 'start',
          //       content: 'label'
          //     }
          //   }]
          // }
        },

        scales: {
          x: CHART_UTILS.X_SCALE,
          y: {...CHART_UTILS.Y_SCALE,
            title: { ...CHART_UTILS.Y_SCALE.title, text: yLabel}
          },
        },
      }}
    />
  ) : null;

  return dataGroup ? (
    <div className="card-container flex-row border-blue mt-24 shadow" id={'chart-' + chartTitle}>
      <div className="card-header bg-light">
        <h3>
          {chartTitle} 
          <span className="title-icons">
           { iconClass && <i className={iconClass}></i>}
          </span>
        </h3>
        <span className="cam-icons">
          <i className="fa fa-camera-retro ico-lg" title="Take a snap" onClick={() => takeASnap()}></i>
        </span>
      </div>
      <div className="card-body">
        {barChart}
      </div>
      </div>
  ) : (
    <Loader />
  );
};

export default BarChart;
