const axios = require("axios").default;

const axiosObj = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
});

export default axiosObj;
