import React, {useState, useEffect} from "react";
import {default as ReactSelect} from 'react-select'
import classes from "./InputHeader.module.css";
import * as DATE_UTILS from "../../services/date.utils";

const InputHeader = ({
  date,
  header,
  dateChangeHandler,
  submitHandler,
  prevNextHandler,
  machineList,
  currentMachine,
  machineChangeHandler,
  operatorList,
  currentOperator,
  operatorChangeHandler
}) => {

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
    // control: (provided) => ({
    //   ...provided,
    //   height: "20px",
    // }),
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => setScroll(window.scrollY > 150));
  }, []);

  return (
    <div className={classes.stickHeader + " container shadow border-blue " + (scroll ? classes.sticky : "")}>
      <div className="md-show"><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
      <div className="flex-item-2 ml-10 md-center">
        <form className={classes.dateForm}>
          <input
            id="queryDate"
            type="date"
            value={date}
            min="2021-01-15"
            max={DATE_UTILS.maxDate()}
            onChange={dateChangeHandler}
          />
          <button onClick={submitHandler} className="ml-10">
            Submit
          </button>
        </form>
      </div>
      <div className="md-show"><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
      <div className="lg-show" style={{width:"14rem"}}>
      {header && (
          <h3 className="pd-0 m-0" style={{width:"12rem", textTransform: "uppercase"}}>
            {header}
          </h3>
        )}
        {machineList && (
          <select id="machine" value={currentMachine.toUpperCase()} onChange={machineChangeHandler}>
            {machineList.map((machine) => (<option key={machine} value={machine}>{machine}</option>))}
          </select>
        )}
        {operatorList && (
          <ReactSelect
            styles={customStyles}
            placeholder="Select Operator"
            options={operatorList}
            isSearchable
            noOptionsMessage={() => "No Operator Found"}
            onChange={(value) => operatorChangeHandler(value)}
        />
        )}
      </div>
      <div className="flex-item-2 lg-show">
        <button
          onClick={() => prevNextHandler("next")}
          className="ml-10 float-right mr-10"
        >
          Next &nbsp;
          <i className="fa fa-forward" aria-hidden="true"></i>
        </button>
        <button
          onClick={() => prevNextHandler("prev")}
          className="ml-10 float-right"
        >
          <i className="fa fa-backward" aria-hidden="true"></i>
          &nbsp; Prev
        </button>
      </div>
    </div>
  );
};

export default InputHeader;
