import React from 'react'
import { styled } from '@mui/material';


const DivTableContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "0 16px 16px 16px",
  borderRadius: "10px",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0 11px 44px 0 rgb(18 18 19 / 10%)",
  marginBottom: "16px",
}));

const StyledTableContainer = (props) => {
  const { children } = props

  return <DivTableContainer>{children}</DivTableContainer>
}

export default StyledTableContainer
