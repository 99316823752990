import React, { useState, useEffect, useMemo } from 'react'
import { styled, Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'
import StyledTable from '../../components_u4/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

//utils
import { MACHINES } from '../../utils/constant.utils'
import * as DATE_UTILS from '../../utils/date.utils'
import { showNotification } from '../../utils/toast.utils'

const CustomForm = styled('form')(() => ({
  display: 'flex',
  flex: 1,
  marginTop: '32px',
}))

const CustomInput = styled('input')(() => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px',
}))

const CustomButton = styled('button')(() => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer',
}))

const formatShift = (shift) => {
  const [_, shiftNumber] = shift.split('_')
  return `S-${shiftNumber}`
}

const evaluateCondtion = (currentObject, filterKeys, filterValues) => {
  //   let condtion = false;
  //   filterKeys.forEach((item) => {
  //     console.log(`${currentObject[item]} === ${filterValues[item]}`);
  //     if (currentObject[item] === filterValues[item]) {
  //       condtion = true;
  //     } else {
  //       condtion = false;
  //     }
  //   });
  //   console.log("condtion", condtion);
  //   return condtion;
  return filterKeys.every((item) => {
    return currentObject[item] == filterValues[item]
  })
}

const getFormattedData = (data, partNumber, operationType, shift) => {
  console.log('Format data function', data)

  const filterValues = {}

  if (partNumber && partNumber !== 0) {
    filterValues['partNumber'] = partNumber
  }

  if (operationType && operationType !== 0) {
    filterValues['operationType'] = operationType
  }

  if (shift && shift !== 0) {
    filterValues['shift'] = shift
  }

  const filter = Object.keys(filterValues)

  const formattedData = []
  const shifts = Object.keys(data)

  for (let j = 0; j < shifts.length; j++) {
    const shiftData = data[shifts[j]]
    const partVariant = Object.keys(shiftData)

    for (let k = 0; k < partVariant.length; k++) {
      const partData = shiftData[partVariant[k]]

      if (partData.partCount === 0) {
        continue
      }

      const productionVariant = partVariant[k].split('-')
      const partNumber = productionVariant[0]
      const operationType = productionVariant[1]
      const workOrder = productionVariant[2]
      const quantity = productionVariant[3]

      const data = {
        index: formattedData.length + 1,
        shift: formatShift(shifts[j]),
        partNumber,
        operationType,
        workOrder,
        quantity,
        ...partData,
      }
      console.log(filter, filter.length > 0)
      if (filter.length > 0) {
        if (evaluateCondtion(data, filter, filterValues)) {
          formattedData.push(data)
        } else {
          continue
        }
      } else {
        console.log('I 11111')
        formattedData.push(data)
      }
    }
  }
  console.log(formattedData)
  return formattedData
}

const PartWise = (props) => {
  //hooks
  const navigate = useNavigate()
  const location = useLocation()


  //component state
  const [allData, setAllData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(DATE_UTILS.todaysDate())
  const [partNumbers, setPartNumbers] = useState([])
  const [operationTypes, setOperationTypes] = useState([])
  const [availableShifts, setAvailableShifts] = useState([])
  const [selectedShift, setSelectedShift] = useState({
    label: 'All shifts',
    value: 0,
  })
  const [selectedPartNumber, setSelectedPartNumber] = useState({
    label: 'All parts',
    value: 0,
  })
  const [selectedOperationType, setSelectedOperationType] = useState({
    label: 'All opeartions',
    value: 0,
  })

  const fetchData = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }

    try {
      setIsLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/unit-4/part-wise`,
        {
          headers: {
            'Authorization': `Bearer ${props.token}`,
          },
          params: params,
        }
      )
      // console.log(response);
      if (response.data.success) {
        const liveData = response.data.data.report
        const partNumbers = response.data.data.partNumbers
        const operationTypes = response.data.data.operationTypes
        const availableShifts = response.data.data.availableShifts

        setAllData(liveData)
        setAvailableShifts()
        setAvailableShifts([
          {
            label: 'All shifts',
            value: 0,
          },
          ...availableShifts.map((item) => {
            const shift = formatShift(item)
            return { label: shift, value: shift }
          }),
        ])
        setPartNumbers([
          {
            label: 'All parts',
            value: 0,
          },
          ...partNumbers.map((item) => ({ label: item, value: item })),
        ])
        setOperationTypes([
          {
            label: 'All operation',
            value: 0,
          },
          ...operationTypes.map((item) => ({ label: item, value: item })),
        ])
        //showNotification(`Machine status fetched successfully`, "success");
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const dateChangeHandler = (e) => {
    setSelectedDate(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    fetchData()
    console.log('submit')
  }

  const formattedData = useMemo(() => {
    const formattedData = getFormattedData(
      allData,
      selectedPartNumber.value,
      selectedOperationType.value,
      selectedShift.value
    )
    return formattedData
    console.log(formattedData)
  }, [
    allData,
    selectedPartNumber.value,
    selectedOperationType.value,
    selectedShift.value,
  ])

  const columns = [
    {
      Header: 'S.No',
      accessor: 'index',
    },
    {
      Header: 'Shift',
      accessor: 'shift',
    },
    {
      Header: 'Part number',
      accessor: 'partNumber',
    },
    {
      Header: 'Operation',
      accessor: 'operationType',
    },
    {
      Header: 'Part count',
      accessor: 'partCount',
    },
    // {
    //   Header: "Target",
    //   accessor: "target",
    // },
    {
      Header: 'Rejection',
      accessor: 'rejectionCount',
    },
    // {
    //   Header: "Idle time",
    //   accessor: "idleTime",
    // },
  ]

  return (
    <>
      <Stack
        spacing={2}
        direction='row'
        sx={{ marginBottom: '16px', width: '100%', alignItems: 'center' }}
      >
        <CustomForm>
          <CustomInput
            id='queryDate'
            type='date'
            value={selectedDate}
            min='2021-01-15'
            max={DATE_UTILS.maxDate()}
            onChange={dateChangeHandler}
          />
          <CustomButton onClick={submitHandler}>Submit</CustomButton>
        </CustomForm>
        <StyledSelectField
          label='Shift'
          placeHolderText='Select shift'
          selectedValue={selectedShift}
          selectOptions={availableShifts}
          onChangeHandler={(value) => setSelectedShift(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage='No shift found'
        />
        <StyledSelectField
          label='Part number'
          placeHolderText='Select part number'
          selectedValue={selectedPartNumber}
          selectOptions={partNumbers}
          onChangeHandler={(value) => setSelectedPartNumber(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage='No part found'
        />
        <StyledSelectField
          label='Operation'
          placeHolderText='Select operation'
          selectedValue={selectedOperationType}
          selectOptions={operationTypes}
          onChangeHandler={(value) => setSelectedOperationType(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage='No operation found'
        />
      </Stack>

      <StyledTableContainer>
        {!isLoading && formattedData.length === 0 && (
          <StyledEmptyHeader>
            There are no parts available, try selecting different date.
          </StyledEmptyHeader>
        )}
        {formattedData.length > 0 && (
          <StyledTable
            pagination={true}
            columns={columns}
            data={formattedData}
            hiddenColumns={[]}
          />
        )}
      </StyledTableContainer>
    </>
  )
}

export default PartWise
