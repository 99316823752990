import React, { useState, useEffect } from "react";
import {useErrorTokenLoading, loading, failure, success} from "../../context/ErrorTokenLoadingContext";

import InputHeader from "../../components/Input/InputHeader";
import Loader from "../../components/Loader/Loader";
import BarChart from "../../components/Charts/BarChart/BarChart";
import IdleTimeCard from "../../components/IdleTimeDoughnut/IdleTimeCard";

import httpApi from "../../api/httpApi";

import * as DATE_UTILS from "../../services/date.utils";
import * as CHART_UTILS from "../../components/Charts/BarChart/BarChart.constant.js";

const IdleTimeReport = () => {

  const {token, elState, elDispatch} = useErrorTokenLoading();

  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate());
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate());
  const [idleTimeData, setIdleTimeData] = useState({});

  useEffect(() => {
    document.title = `DPS | Idletime Report`;
  }, []);

  useEffect(() => {
    const fetchMyAPI = async () => {
      try {
        elDispatch(loading());
        const response = await httpApi.getData(token, '/idletime', { currentDate: workingDate });
        //console.log(response);
        setIdleTimeData(response);
        elDispatch(success());
      } catch (err) {
        elDispatch(failure(err.response));
        //console.log(err);
      }
    };
    fetchMyAPI();
  }, [workingDate, token]);

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //setWorkingDate(event.target.form.queryDate.value);
    setWorkingDate(calenderDate);
  };

  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
  };

  const chartTitle = idleTimeData["idleTimeTrend"] ? `Idle-Time Trend` : "";

  const chartLabels = idleTimeData["idleTimeTrend"]
    ? idleTimeData["idleTimeTrend"].map((el) => el["date_of_job"])
    : [];

  const dataGroup = idleTimeData["idleTimeTrend"]
    ? [
        {
          ...CHART_UTILS.BAR,
          label: "Shift-1",
          data: idleTimeData["idleTimeTrend"].map((el) => el["s1_idletime"]),
          backgroundColor: "#7afade",
          borderColor: "#04b890",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Shift-2",
          data: idleTimeData["idleTimeTrend"].map((el) => el["s2_idletime"]),
          backgroundColor: "#f5ae67",
          borderColor: "#b55d05",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day",
          data: idleTimeData["idleTimeTrend"].map((el) => el["daily_idletime"]),
          backgroundColor: "#fa9ba6",
          borderColor: "#fa465b",
        },
      ]
    : [];

  return (
    <div className="max-container">
      <InputHeader
        date={calenderDate}
        header="Idle-Time Report"
        dateChangeHandler={dateChangeHandler}
        submitHandler={submitHandler}
        prevNextHandler={prevNextHandler}
      />

      {elState.loading && <Loader />}
      {!elState.loading && (
        <>
          <BarChart
            chartTitle={chartTitle}
            chartLabels={chartLabels}
            dataGroup={dataGroup}
            iconClass="fa fa-line-chart"
            xLabel="DAYS"
            yLabel="IDLE-TIME"
          />
          <div className="flex-container">
            <div className="flex-item-1 mb-0">
              {idleTimeData["idleTimeData"] && (
                <IdleTimeCard idleTimeData={idleTimeData["idleTimeData"]} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IdleTimeReport;
