import React from "react";
import { VistaTable } from "vista-table-react";
import OperatorDetails from "../OperatorDetails/OperatorDetails";

const OperatorTable = ({ operatorData }) => {

  const tableHeader = [
    {key:"date_of_job", title: "Date"},
    {key:"shift", title: "Shift"},
    {key:"machine", title: "Machine"},
    {key:"part_type", title: "Part Type"},
    {key:"achieved_qty", title: "Achieved"},
    {key: "target",title: "Target"},
    {key:"idletime", title: "Idletime"},
    {key:"rejection", title: "Rejection"},
    {key:"oee", title: "OEE"},
    {key: "line_inspector",title: "Line Inspector"},
    {key: "supervisor",title: "Supervisor"}
  ];

  const tableEntries = operatorData.map((rowObj) => ({
      date_of_job:rowObj["date_of_job"].slice(0, 10),
      shift:rowObj["shift"],
      machine: rowObj["machine"],
      part_type:rowObj["part_type"],
      achieved_qty: rowObj["achieved_qty"],
      target: rowObj["target"],
      idletime: rowObj["idletime"],
      rejection: rowObj["rejection"],
      oee: rowObj["oee"],
      line_inspector : rowObj["line_inspector"],
      supervisor : rowObj["supervisor"],
    }));

  return (
    <div className="card-container flex-row border-thin mt-5">
      <OperatorDetails operatorData={operatorData} />
      <hr style={{ width: "98%" }} className="hr" />
      <VistaTable headers={tableHeader} data={tableEntries} />
    </div>
  );
};

export default OperatorTable;
