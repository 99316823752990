import React from "react";
import { styled } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: theme.zIndex.drawer + 1,
  background: "transparent",
  backgroundColor: "rgba(0,0,0,0.1)",
  color: theme.palette.secondary.main,
}));

export default function LoadingSpinner(props) {
  const isLoading = props.loading;

  return (
    <div>
      <StyledBackdrop open={Boolean(isLoading)}>
        {props.text ? props.text : ""}
        <CircularProgress color="inherit" />
      </StyledBackdrop>
    </div>
  );
}
