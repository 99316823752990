import React from "react";
import { styled } from "@mui/material";
import ReactSelect from "react-select";

//components
import CustomRadio from "../../../components/CustomRadio/CustomRadio";

import { MACHINES } from "../../../utils/constant.utils";
import * as DATE_UTILS from "../../../utils/date.utils";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "80%",
  }),
  control: (provided) => ({
    ...provided,
    height: "40px",
  }),
};

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const CustomForm = styled("form")(({ theme }) => ({
  display: "flex",
  flex: 1,
}));

const SelectContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: "16px",
  flex: 1,
  // width: "500px",
  // marginLeft: "auto",
}));

const CustomInput = styled("input")(({ theme }) => ({
  height: "40px",
  margin: "2px",
  minWidth: "150px",
  borderRadius: "8px",
  cursor: "pointer",
  padding: "8px",
}));

const CustomButton = styled("button")(({ theme }) => ({
  height: "40px",
  margin: "2px",
  minWidth: "150px",
  borderRadius: "8px",
  cursor: "pointer",
}));

const Selection = ({
  selectedDate,
  dateChangeHandler,
  submitHandler,
  selectedMachine,
  setSelectedMachine,
  selectedShift,
  availableShifts,
  onShiftSelect
}) => {
  return (
    <Container>
      <CustomForm>
        <CustomInput
          id="queryDate"
          type="date"
          value={selectedDate}
          min="2021-01-15"
          max={DATE_UTILS.maxDate()}
          onChange={dateChangeHandler}
        />
        <CustomButton onClick={submitHandler} className="ml-10">
          Submit
        </CustomButton>
      </CustomForm>
      <SelectContainer>
        <div>Select machine</div>
        <ReactSelect
          styles={customStyles}
          placeholder="Select Machine"
          options={MACHINES}
          value={selectedMachine}
          isSearchable
          noOptionsMessage={() => "No machine Found"}
          onChange={(value) => setSelectedMachine(value)}
        />
      </SelectContainer>
      <div style={{display:'flex', marginLeft: 'auto'}}>
        {availableShifts.map((data) => (
          <CustomRadio
            title={data}
            onSelect={() => onShiftSelect(data)}
            condition={selectedShift === data}
          />
        ))}
      </div>
    </Container>
  );
};

export default Selection;
