import React from "react";

import BarChart from "../../../components_u4/Charts/BarChart";

import * as CHART_UTILS from "../../../components_u4/Charts/BarChart.constant";

const Rejection = ({ isLoading, groupedData }) => {
  const chartTitle = "Hourly part produced";

  const chartLabels = [];
  const data = [];

  if (groupedData) {
    for (const [hour, hourlData] of Object.entries(groupedData)) {
      let totalQuantity = 0;

      if (!Array.isArray(hourlData)) {
        chartLabels.push(hour);
        data.push(totalQuantity);
        continue;
      }

      hourlData.forEach((data) => {
        totalQuantity += data.rejectionCount;
      });

      chartLabels.push(hour);
      data.push(totalQuantity);
    }
  }

  const dataGroup = [
    {
      ...CHART_UTILS.BAR,
      label: "Rejection",
      data: data,
      backgroundColor: "#7afade",
      borderColor: "#04b890",
    },
    // {
    //   ...CHART_UTILS.BAR,
    //   label: "Shift-2",
    //   data: dasboardData["oeeTrend"].map((el) => el["s2_oee"]),
    //   backgroundColor: "#f5ae67",
    //   borderColor: "#b55d05",
    // },
  ];

  if (isLoading) {
    return <div>Loading....</div>;
  }

  return (
    <div
      style={{
        height: "40%",
      }}
    >
      <BarChart
        chartTitle={chartTitle}
        chartLabels={chartLabels}
        dataGroup={dataGroup}
        xLabel="Hours"
        yLabel="Rejection quantity"
        minValue={0}
        maxValue={100}
        stepValue={5}
      />
    </div>
  );
};

export default Rejection;
