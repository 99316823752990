import React from "react";
import {VistaTable} from "vista-table-react";

const AchieversTable = ({ achieversData, tableTitle, isAchiever }) => {
  const tableHeader = [
    {key:"operator_id", title: "Operator ID"},
    {key:"operator_name", title: "Operator"},
    {key:"machine_name", title: "Machine"},
    {key:"target", title: "Target"},
    {key:"achieved_qty", title: "Achieved"},
    {key:"percent", title: "Efficiency"}
  ];

  const tableData = isAchiever
    ? achieversData.filter((el) => (el["achieved_qty"] / el["target"]) * 100 > 90)
    : achieversData.filter((el) => (el["achieved_qty"] / el["target"]) * 100 < 90)
    //: achieversData.slice(achieversData.length - 5);

  const tableEntries = tableData.map((rowObj) => ({
      operator_id:rowObj["operator_id"],
      operator_name:rowObj["operator_name"],
      machine_name: rowObj["machine_name"],
      target:rowObj["target"],
      achieved_qty: rowObj["achieved_qty"],
      percent: `${parseInt((rowObj["achieved_qty"] / rowObj["target"]) * 100)}%`
    }));

  return (
    <div className="card-container flex-row border-thin mt-5">
      <div className="card-header bg-grey">
        <h3>{tableTitle}</h3>
        <span className="cam-icons">
          <i
            className="fa fa-camera-retro ico-lg"
            title="Take a snap"
            // onClick={() => takeASnap()}
          ></i>
        </span>
      </div>
      <div className="card-body pd-0">
        <VistaTable headers={tableHeader} data={tableEntries} />
      </div>
    </div>
  );
};

export default AchieversTable;
