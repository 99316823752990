import React from "react";
import Typography from "@mui/material/Typography";
import ReactSelect from "react-select";
import { styled } from "@mui/material";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "8px",
    backgroundColor: " #f9fafc",
    boxShadow: "0 2px 7px 0 rgb(18 18 19 / 6%)",
    transition:
      "box-shadow 300ms ease, color 300ms ease, border-color 300ms ease",
    "&:hover": {
      borderColor: "#a8a8b5",
      boxShadow: "0 2px 12px 0 rgb(18 18 19 / 10%)",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "46px",
    padding: "0 20px",
    overflow: "auto",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#121213",
  }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: "16px",
    borderRadius: "8px",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "8px",
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: "8px",
  }),
};

const DivInputField = styled("div")(({ theme, enableMargin }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginTop: enableMargin ? "32px" : "0px",
  width: "100%",
}));

const DivInputLabelBox = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 1,
  justifyContent: "flex-start",
  marginBottom: "8px",
}));

const DivInputBox = styled("div")(({ theme }) => ({
  display: "flex",
  flex: 3,
}));

const TypographyInputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
}));

const StyledSelectField = (props) => {
  const {
    enableMargin,
    label,
    placeHolderText,
    selectedValue,
    selectOptions,
    onChangeHandler,
    isMulti,
    isSearchable,
    isDisabled,
    noOptionsMessage,
    isOptionDisabled,
    isRequired
  } = props;

  return (
    <DivInputField enableMargin={enableMargin || false}>
      <DivInputLabelBox>
        <TypographyInputLabel variant="h5">
          {label} {isRequired && <sup style={{ fontSize: "16px" }}>*</sup>}
        </TypographyInputLabel>
      </DivInputLabelBox>
      <DivInputBox>
        <ReactSelect
          isClearable={false}
          isMulti={isMulti}
          isSearchable={isSearchable}
          styles={customStyles}
          placeholder={placeHolderText}
          value={selectedValue}
          options={selectOptions}
          onChange={onChangeHandler}
          isDisabled={isDisabled}
          noOptionsMessage={() => noOptionsMessage}
          isOptionDisabled={isOptionDisabled ? isOptionDisabled : () => false}
        />
      </DivInputBox>
    </DivInputField>
  );
};

export default StyledSelectField;
