import React from "react";
import { VistaTable } from "vista-table-react";

const OperatorwiseProdInfo = ({ data, tableTitle }) => {
  const tableHeader = [
    { key: "shift", title: "Shift" },
    { key: "operator_id", title: "Operator ID" },
    { key: "type", title: "Operator" },
    { key: "achieved_qty", title: "Achieved Quantity" },
  ];

  const tableEntries = data ? data.map((rowObj) => ({
    shift: rowObj["shift"],
    operator_id:rowObj["operator_id"],
    type: rowObj["type"],
    achieved_qty: rowObj["achieved_qty"],
  })): [];

  return (
    <div className="card-container flex-row border-thin mt-5">
      <div className="card-header bg-grey">
        <h3>{tableTitle}
         <span className="title-icons">
            <i className="fa fa-address-book-o"></i>
          </span>
        </h3>
        <span className="cam-icons">
          <i
            className="fa fa-camera-retro ico-lg"
            title="Take a snap"
            // onClick={() => takeASnap()}
          ></i>
        </span>
      </div>
      <div className="card-body pd-0">
        <VistaTable headers={tableHeader} data={tableEntries} />
      </div>
    </div>
  );
};

export default OperatorwiseProdInfo;
