import React, { useState, useEffect, useMemo } from 'react'
import { styled, Button } from '@mui/material'
import Stack from '@mui/material/Stack'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

//components
import StyledTable from '../../components_u4/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import RadialGauge from '../../components/RadialGauge/RadialGauge'

//utils
import { MACHINES } from '../../utils/constant.utils'
import * as DATE_UTILS from '../../utils/date.utils'
import { showNotification } from '../../utils/toast.utils'
import { calculateKpiParameters } from './helper'

const CustomForm = styled('form')(() => ({
  display: 'flex',
  flex: 1,
  marginTop: '32px',
}))

const CustomInput = styled('input')(() => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px',
}))

const CustomButton = styled('button')(() => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer',
}))

const OEEWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}))

const OEECard = styled('div')(() => ({
  display: 'flex',
  flexGrow: 1,
  columnGap: '16px',
  justifyContent: 'center',
  alignItems: 'center',
}))

const formatShift = (shift) => {
  const [_, shiftNumber] = shift.split('_')
  return `S-${shiftNumber}`
}

const OEEList = (data) => {
  if (data && data.length === 0) {
    return []
  }

  const shiftWiseData = {}

  data.forEach((item) => {
    const shift = item.shift
    if (!shiftWiseData[shift]) {
      shiftWiseData[shift] = {}
      shiftWiseData[shift]['partCount'] = 0
      shiftWiseData[shift]['rejectionCount'] = 0
      shiftWiseData[shift]['target'] = 0
      shiftWiseData[shift]['idleTime'] = 0
      shiftWiseData[shift]['powerCut'] = 0
    }

    shiftWiseData[shift]['partCount'] += item.partCount
    shiftWiseData[shift]['rejectionCount'] += item.rejectionCount
    shiftWiseData[shift]['target'] += item.target
    shiftWiseData[shift]['idleTime'] += item.idleTime
    shiftWiseData[shift]['powerCut'] += item.powerCut
  })

  return shiftWiseData
}

const getFormattedData = (data, machine) => {
  //console.log("Format data function", data, machine);

  const formattedData = []
  const machineList = Object.keys(data)

  for (let i = 0; i < machineList.length; i++) {
    const machineData = data[machineList[i]]
    const shifts = Object.keys(machineData)

    for (let j = 0; j < shifts.length; j++) {
      const shiftData = machineData[shifts[j]]
      const partVariant = Object.keys(shiftData)

      for (let k = 0; k < partVariant.length; k++) {
        const partData = shiftData[partVariant[k]]

        if (partData.partCount === 0) {
          continue
        }

        const productionVariant = partVariant[k].split('-')
        const partNumber = productionVariant[0]
        const operationType = productionVariant[1]
        const workOrder = productionVariant[2]
        const quantity = productionVariant[3]
        // const operationType = productionVariant.slice(1, -2);
        // const workOrder = productionVariant[productionVariant.length - 2];
        // const quantity = productionVariant[productionVariant.length - 1];

        const data = {
          machine: machineList[i],
          shift: formatShift(shifts[j]),
          partNumber,
          operationType,
          workOrder,
          quantity,
          ...partData,
        }

        if (machine && machine !== 0) {
          console.log('condition')
          if (data.machine === String(machine).toUpperCase()) {
            formattedData.push(data)
          }
        } else {
          formattedData.push(data)
        }
      }
    }
  }

  const oeeList = []
  const shiftOeeData = OEEList(formattedData)
  const shiftOee = Object.keys(shiftOeeData)

  if (shiftOee && shiftOee.length > 0) {
    let totalOee = 0
    shiftOee.forEach((item, index) => {
      const data = shiftOeeData[item]
      const OEE = calculateKpiParameters({
        shiftTarget: data.target,
        powerCut: data.powerCut,
        partCount: data.partCount,
        rejectionCount: data.rejectionCount,
      })
      totalOee += OEE

      oeeList.push({
        shift: item,
        oee: OEE,
      })

      if (index === shiftOee.length - 1) {
        oeeList.push({
          shift: 'Daily',
          oee: totalOee / 2,
        })
      }
    })
  }

  return { formattedData, oeeList }
}

const machineOptions = [{ label: 'All machines', value: 0 }, ...MACHINES]

const MachineWise = (props) => {
  //hooks
  const navigate = useNavigate()
  const location = useLocation()

  //component state
  const [allData, setAllData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState(DATE_UTILS.todaysDate())
  const [machine, setMachine] = useState(machineOptions[0])

  const fetchData = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }

    try {
      setIsLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/unit-4/machine-wise`,
        {
          headers: {
            'Authorization': `Bearer ${props.token}`,
          },
          params: params,
        }
      )
      // console.log(response);
      if (response.data.success) {
        const liveData = response.data.data.report
        setAllData(liveData)
        //showNotification(`Machine status fetched successfully`, "success");
      } else {
        showNotification(response.data.message, 'error')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const dateChangeHandler = (e) => {
    setSelectedDate(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    fetchData()
    console.log('submit')
  }

  //const formattedData = useMemo(() => {
  const { formattedData, oeeList } = getFormattedData(allData, machine.value)
  //console.log("formattedData", formattedData);
  //return formattedData;

  //}, [allData, machine]);

  const columns = [
    {
      Header: 'Machine',
      accessor: 'machine',
    },
    {
      Header: 'Shift',
      accessor: 'shift',
    },
    {
      Header: 'Part number',
      accessor: 'partNumber',
    },
    {
      Header: 'Operation',
      accessor: 'operationType',
    },
    {
      Header: 'Work order',
      accessor: 'workOrder',
    },
    {
      Header: 'WO quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Part count',
      accessor: 'partCount',
    },
    {
      Header: 'Target',
      accessor: 'target',
    },
    {
      Header: 'Rejection',
      accessor: 'rejectionCount',
    },
    {
      Header: 'Idle time',
      accessor: 'idleTime',
    },
  ]

  return (
    <>
      <Stack
        spacing={2}
        direction='row'
        sx={{ marginBottom: '16px', width: '100%', alignItems: 'center' }}
      >
        <CustomForm>
          <CustomInput
            id='queryDate'
            type='date'
            value={selectedDate}
            min='2021-01-15'
            max={DATE_UTILS.maxDate()}
            onChange={dateChangeHandler}
          />
          <CustomButton onClick={submitHandler}>Submit</CustomButton>
        </CustomForm>
        <StyledSelectField
          label='Machine'
          placeHolderText='Select machine'
          selectedValue={machine}
          selectOptions={machineOptions}
          onChangeHandler={(value) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage='No machine found'
        />
      </Stack>
      <OEEWrapper>
        {oeeList &&
          oeeList.length > 0 &&
          oeeList.map((shift) => (
            <OEECard>
              <h2>{shift.shift}</h2>
              <RadialGauge value={shift.oee} />
            </OEECard>
          ))}
      </OEEWrapper>
      <StyledTableContainer>
        {!isLoading && formattedData.length === 0 && (
          <StyledEmptyHeader>
            There are no parts available, try selecting different date.
          </StyledEmptyHeader>
        )}
        {formattedData.length > 0 && (
          <StyledTable
            pagination={true}
            columns={columns}
            data={formattedData}
            hiddenColumns={[]}
          />
        )}
      </StyledTableContainer>
    </>
  )
}

export default MachineWise
