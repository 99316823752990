import React, { useState } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import StyledModal from '../../components/StyledModal/StyledModal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'

import { MACHINES } from '../../utils/constant.utils'

const machineOptions = MACHINES

const PartUploadModal = (props) => {
  let defaultPartName = ''
  let defaultPartType = ''
  let defaultMachine = {
    value: 0,
    label: 'Select machine',
  }
  let defaultMachiningTime = ''
  let defaultLoadUnloadTime = ''

  if (props.isEditMode) {
    defaultPartName = props.selectedPart.name
    defaultPartType = props.selectedPart.type
    defaultMachine = {
      value: props.selectedPart.machine,
      label: props.selectedPart.machine.toUpperCase(),
    }
    defaultMachiningTime = props.selectedPart.machiningTime
    defaultLoadUnloadTime = props.selectedPart.loadUnloadTime
  }

  const [partName, setPartName] = useState(defaultPartName)
  const [partType, setPartType] = useState(defaultPartType)
  const [machine, setMachine] = useState(defaultMachine)
  const [machiningTime, setMachiningTime] = useState(defaultMachiningTime)
  const [loadUnloadTime, setLoadUnloadTime] = useState(defaultLoadUnloadTime)

  return (
    <StyledModal
      modalWidth={500}
      modalHeight={700}
      loaderState={props.isLoading}
      isModalOpen={props.isModalOpen}
      modalTitle={props.isEditMode ? 'Edit part ' : 'Add part'}
      modalCloseHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction='column'
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledTextFieldWithLabel
          label='Part number'
          placeholderText='Enter part number'
          textValue={partName}
          onChangeHandler={(e) => setPartName(e.target.value)}
          isDisabled={false}
          type='text'
          isMargin={true}
        />
        <StyledTextFieldWithLabel
          label='Operation type'
          placeholderText='Enter operation type'
          textValue={partType}
          onChangeHandler={(e) => setPartType(e.target.value)}
          isDisabled={false}
          type='text'
          isMargin={true}
        />
        <StyledSelectField
          label='Machine'
          placeHolderText='Select machine'
          selectedValue={machine}
          selectOptions={machineOptions}
          onChangeHandler={(value) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage='No machine found'
        />
        <StyledTextFieldWithLabel
          label='Machining time (mm:ss)'
          placeholderText='Enter machining time. Eg: 01:50'
          textValue={machiningTime}
          onChangeHandler={(e) => setMachiningTime(e.target.value)}
          isDisabled={false}
          onBlurHandler
          type='text'
        />
        <StyledTextFieldWithLabel
          label='Load and unload time (mm:ss)'
          placeholderText='Enter load and unload time. Eg: 01:50'
          textValue={loadUnloadTime}
          onChangeHandler={(e) => setLoadUnloadTime(e.target.value)}
          isDisabled={false}
          type='text'
        />
      </Stack>
      <Stack
        spacing={2}
        direction='row'
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant='outlined' onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() =>
            props.isEditMode
              ? props.editPart({
                  id: props.selectedPart.id,
                  machiningTime,
                  loadUnloadTime,
                  partName,
                  partType,
                  machine,
                })
              : props.addPart({
                  machiningTime,
                  loadUnloadTime,
                  partName,
                  partType,
                  machine,
                })
          }
        >
          {props.isEditMode ? 'Update' : 'Add part'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default PartUploadModal
