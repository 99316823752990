import React, { useEffect } from 'react'
import { useTable, usePagination, useFilters } from 'react-table'

import Pagination from '../Pagination/Pagination'
import classes from './ReactTable.module.css'

const ReactTable = (props) => {
  const columns = React.useMemo(() => props.columns, [props.columns])
  const data = React.useMemo(() => props.data, [props.data])

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },
    useFilters,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex },
  } = tableInstance

  useEffect(() => {
    setPageSize(10)
  }, [setPageSize])

  return (
    <>
      <table className={classes.reactTable} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Pagination
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
      />
    </>
  )
}

export default ReactTable
