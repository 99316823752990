const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const maxDate = () => {
  let d = new Date()
  //d.setDate(d.getDate() - 1)
  return d.toISOString().slice(0, 10)
}

export const todaysDate = () => {
  let d = new Date()
  return d.toISOString().slice(0, 10)
}

export const workingDate = () => {
  let d = new Date()
  d.setDate(d.getDate() - 1)
  return d.toISOString().slice(0, 10)
}

const isFutureDate = (currentDate) => {
  const todaysDate = new Date()
  todaysDate.setDate(todaysDate.getDate() - 1)
  if (currentDate >= todaysDate) {
    return true
  }
  return false
}

export const previousDate = (currentDate) => {
  let d = new Date(currentDate)
  d.setDate(d.getDate() - 1)
  if (!isFutureDate(d)) {
    return d.toISOString().slice(0, 10)
  }
  return currentDate
}

export const nextDate = (currentDate) => {
  let d = new Date(currentDate)
  d.setDate(d.getDate() + 1)
  if (!isFutureDate(d)) {
    return d.toISOString().slice(0, 10)
  }
  return currentDate
}

export const updateDate = (actionType, prevDate) => {
  return actionType === 'prev' ? previousDate(prevDate) : nextDate(prevDate)
}

export const getMonthAndYear = (queryDate) => {
  let d = new Date(queryDate)
  return { sMonth: monthsArray[d.getMonth()], sYear: d.getFullYear() }
}

export const dayGetter = (dateString) => {
  const dd = dateString.slice(0, 2)
  const mm = dateString.slice(3, 5)
  const yyyy = dateString.slice(6)
  //console.log(`${yyyy}-${mm}-${dd}`);
  const reqDate = new Date(`${yyyy}-${mm}-${dd}`)
  //console.log(reqDate);
  const d = new Date(reqDate.getFullYear(), reqDate.getMonth() + 1, 0)
  return {
    daysInMonth: d.getDate(),
    monthOfReport: monthsArray[reqDate.getMonth()],
  }
}
