export const routes = [
  {
    path: '/unit-1/dashboard',
    text: 'Dashboard',
    icon: '',
  },
  {
    path: '/unit-1/demography',
    text: 'Demography',
    icon: '',
  },
  {
    path: '/unit-1/prodreport',
    text: 'Production Report',
    icon: '',
  },
  {
    path: '/unit-1/idletime',
    text: 'Idle-Time Report',
    icon: '',
  },
  {
    path: '/unit-1/rejection',
    text: 'Rejection Report',
    icon: '',
  },
  {
    path: '/unit-1/operatorinfo',
    text: 'Operator Info',
    icon: '',
  },
]
