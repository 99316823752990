import React, { useState } from "react";
import DoughnutChart from "../Charts/DonutChart/DoughnutChart";
import { idletimeDataMapper } from "../../services/common.utlis";

const IdleTimeCard = ({ idleTimeData, isForMachine }) => {
  const [timeFrame, setTimeFrame] = useState("monthlyData");
  const [shift, setShift] = useState("daily");

  const chartData = idletimeDataMapper(idleTimeData, timeFrame, shift);

  const totalCount = chartData
    ? chartData["values"].reduce((accu, element) => accu + element, 0)
    : 0;

  return (
    <div className="card-container border-blue mt-24 shadow">
      <div className="card-header bg-light">
        <h3>
          Idletime Data
          <span className="title-icons">
            <i className="fa fa-clock-o"></i>
          </span>
        </h3>
        <div className="ml-10">
          <select
            id="timeFrame"
            value={timeFrame}
            onChange={(event) => setTimeFrame(event.target.value)}
          >
            <option value="dailyData">Day</option>
            <option value="monthlyData">Month</option>
          </select>
        </div>
        <span className="cam-icons">
          <i
            className="fa fa-camera-retro ico-lg"
            title="Take a snap"
            //onClick={takeASnap}
          ></i>
        </span>
      </div>
      <div className="card-body">
        <div className="flex-container">
          <div>
            <select
              id="shiftNum"
              value={shift}
              onChange={(event) => setShift(event.target.value)}
            >
              <option value="shift1">Shift1</option>
              <option value="shift2">Shift2</option>
              <option value="daily">Day</option>
            </select>
          </div>
        </div>
        <div id="idleTime-chart">
          <DoughnutChart
            chartTitle=""
            chartLabels={chartData.labels}
            dataGroup={chartData.values}
          />
        </div>
      </div>
      <div className="card-footer flex-row bg-light">
        <h4 className="flaot-right">
          Total Idle-Time
          {timeFrame === "monthlyData" && !isForMachine  ? (
            <span>: {totalCount && (totalCount / (60 * 24)).toFixed(1)} Days</span>
          ) : (
            <span>: {totalCount && (totalCount / 60).toFixed(1)} Hours</span>
          )}
          {isForMachine && (
            <span>: {totalCount && totalCount.toFixed(0)} Minutes</span>
            )}
        </h4>
      </div>
    </div>
  );
};

export default IdleTimeCard;
