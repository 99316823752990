import axios from './axios'

const shiftConfigService = {
  getShiftConfigById: async (id, token) => {
    try {
      const response = await axios.get(`/unit-4/shift-config/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getShiftConfigs: async (token) => {
    try {
      const response = await axios.get('/unit-4/shift-config', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  addShiftConfig: async (data, token) => {
    try {
      const response = await axios.post('/unit-4/shift-config', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  updateShiftConfig: async (id, data, token) => {
    try {
      const response = await axios.patch(`/unit-4/shift-config/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  deleteShiftConfig: async (id, data, token) => {
    try {
      const response = await axios.delete(`/unit-4/shift-config/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  selectShiftConfig: async (id, arrayOfIds, token) => {
    try {
      const response = await axios.patch(
        `/unit-4/shift-config/select/${id}`,
        {
          arrayOfIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
}

export default shiftConfigService
