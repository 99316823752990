import React from "react";
import LiveDataBar from "./LiveDataBar";
import CustomAccordion from "./CustomAccordion";

const LiveDataDetailedBar = ({ liveData, width }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
        maxWidth: `${width}px`,
      }}
    >
      {React.Children.toArray(
        Object.keys(liveData).map((key) => (
          <CustomAccordion time={`${key}:00`}>
            <div
              style={{
                position: "relative",
              }}
            >
              <LiveDataBar
                liveData={liveData[key]}
                width={width}
                count={60}
                isBorder={true}
              />
            </div>
          </CustomAccordion>
        ))
      )}
    </div>
  );
};

export default LiveDataDetailedBar;
