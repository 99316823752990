import React from 'react'

//icons
import DashboardIcon from '@mui/icons-material/Dashboard'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import ExtensionIcon from '@mui/icons-material/Extension'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import CellTowerIcon from '@mui/icons-material/CellTower'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import ListIcon from '@mui/icons-material/List'
import EngineeringIcon from '@mui/icons-material/Engineering'
import PersonIcon from '@mui/icons-material/Person';

export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/unit-4/dashboard',
    renderIcon: () => {
      return <DashboardIcon fontSize='small' />
    },
  },
  {
    title: 'Live data',
    path: '/unit-4/live-data?layout=table',
    renderIcon: () => {
      return <CellTowerIcon fontSize='medium' />
    },
  },
  {
    title: 'Machine wise',
    path: '/unit-4/machine-wise?tab=overview',
    renderIcon: () => {
      return <RssFeedIcon fontSize='medium' />
    },
  },
  {
    title: 'Production record',
    path: '/unit-4/production-record',
    renderIcon: () => {
      return <ListIcon fontSize='medium' />
    },
  },
  {
    title: 'Config',
    path: '/unit-4/config?tab=config-list',
    renderIcon: () => {
      return <EngineeringIcon fontSize='medium' />
    },
  },
  {
    title: 'Employee',
    path: '/unit-4/employee-management',
    renderIcon: () => {
      return <PersonIcon fontSize='medium' />
    },
  },
  {
    title: 'Parts',
    path: '/unit-4/part-management?tab=part-list',
    renderIcon: () => {
      return <ExtensionIcon fontSize='medium' />
    },
  },
  {
    title: 'HMI updates',
    path: '/unit-4/hmi-update',
    renderIcon: () => {
      return <CloudSyncIcon fontSize='medium' />
    },
  },
]
