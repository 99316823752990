import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "@emotion/styled/macro";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { showNotification } from "../../../utils/toast.utils";
import { capitalizeFirstLetter } from "../../../utils/string.utils";
import RippleEffect from "../../../components/RippleEffect/RippleEffect";

const Detail = ({ label, value }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        //justifyContent: "space-between",
        alignItems: "center",
        columnGap: "16px",
        fontWeight: 500,
      }}
    >
      <p>{label}</p>
      <div
        style={{
          fontWeight: 700,
        }}
      >
        {value}
      </div>
    </div>
  );
};

const CardContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 4px;
  margin-top: 16px;
  //background-color: !isActive ? "red" : "transparent"
`;

const Card = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  padding: 16px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 8px 28px 0 rgb(18 18 19 / 10%);
  text-align: center;
`;

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Opeartor = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const OpeartorName = styled.p`
  font-size: 20px;
`;

const MachineTitleContainer = styled.p`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const MachineTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

const CardBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;

const SingleMachineCard = ({ machine, token }) => {
  const [machineData, setMachineData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchMachineStatus = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/unit-4/live-data/${machine.toLowerCase()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
        //console.log(response);
        if (response.data.success) {
          const data = response.data.data;
          setMachineData({
            machine: capitalizeFirstLetter(data.machine),
            name: data.partName,
            type: data.partType,
            partCount: data.partCount,
            rejectionCount: data.rejectionCount,
            machineStatus: data.machineStatus,
            operator: data.operator,
          });
          //showNotification(`Machine status fetched successfully`, "success");
        } else {
          showNotification(response.data.message, "error");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchMachineStatus();
    const id = setTimeout(() => {
      fetchMachineStatus();
    }, 5000);

    return () => {
      clearTimeout(id);
    };
  }, [machine]);

  if (isLoading) {
    return (
      <CardContainer>
        <Card>Loading...</Card>
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <Card>
        <CardHeader>
          <MachineTitleContainer>
            <MachineTitle>{machineData.machine}</MachineTitle>
            <RippleEffect
              machineStatus={
                machineData.machineStatus === true ||
                machineData.machineStatus === 1
                  ? false
                  : true
              }
            />
          </MachineTitleContainer>
          <Opeartor>
            <ManageAccountsIcon fontSize="large" />
            <OpeartorName>{machineData.operator}</OpeartorName>
          </Opeartor>
        </CardHeader>
        <CardBody>
          <Detail label="Part:" value={machineData.name} />
          <Detail label="Operation:" value={machineData.type} />
          <Detail label="Part count:" value={machineData.partCount} />
          <Detail label="Rejection:" value={machineData.rejectionCount} />
        </CardBody>
      </Card>
    </CardContainer>
  );
};

export default SingleMachineCard;
