import React, { useState, useEffect, useRef } from "react";
import { styled, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useErrorTokenLoading,
  loading,
  failure,
  success,
} from '../../context/ErrorTokenLoadingContext'

//components
import StyledPageContainer from "../../components/StyledPageContainer/StyledPageContainer";
import StyledPageHeader from "../../components/StyledPageHeader/StyledPageHeader";
import Selection from "./components/Selection";
import Overview from "./components/Overview";
import HourlyTrend from "./components/HourlyTrend";
import Rejection from "./components/Rejection";
import Idletime from "./components/Idletime";
import Production from "./components/Production";

//utils
import { MACHINES } from "../../utils/constant.utils";
import * as DATE_UTILS from "../../utils/date.utils";
import { showNotification } from "../../utils/toast.utils";

//service
import liveDataService from "../../services/liveData.service";
import SingleMachineCard from "./components/SingleMachineCard";

const liveDataTabs = [
  { name: "Overview", value: "overview" },
  { name: "Hourly trend", value: "hourlyTrend" },
  { name: "Rejection", value: "rejection" },
  // { name: "Idletime", value: "idletime" },
  // { name: "Production", value: "production" },
];

const TabsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  columnGap: "24px",
  backgroundColor: theme.palette.background.paper,
  padding: "8px 24px 0 0",
  marginBottom: "16px",
  marginTop: "32px",
}));

const Tab = styled("div")(({ theme, isActive }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: "16px",
  minWidth: "fit-content",
  padding: "8px 0px",
  color: isActive ? theme.palette.primary.main : "#ccccd7",
  cursor: "pointer",
  borderBottom: isActive
    ? `1px solid ${theme.palette.primary.main}`
    : "1px solid transparent",
  "&:hover": {
    color: `${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

const MachineStatus = () => {
  //default values
  let defaultTab = liveDataTabs[0].name;
  let defaultMachine = {
    value: MACHINES[0].label,
    label: MACHINES[0].value,
  };

  const { token } = useErrorTokenLoading()

  //hooks
  const navigate = useNavigate();
  const location = useLocation();

  //extract category from URL
  const params = new URLSearchParams(location.search);
  const tab = params.get("tab");
  const machine = params.get("machine");

  //If category present in the URL override the defaultJobCategory
  if (tab) {
    defaultTab = tab;
  }

  if (machine) {
    defaultMachine.label = machine.toLowerCase();
    defaultMachine.value = machine.toLowerCase();
  }

  //component state
  const [allData, setAllData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(DATE_UTILS.todaysDate());
  const [selectedMachine, setSelectedMachine] = useState(defaultMachine);
  const [activeSubTab, setActiveSubTab] = useState(defaultTab);
  const [availableShifts, setAvailableShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState("shift1");

  useEffect(() => {
    setActiveSubTab(defaultTab);
  }, [defaultTab]);

  const fetchMachineData = async () => {
    const params = {};

    if (selectedMachine.value !== 0) {
      params.machine = selectedMachine.value;
    }
    if (selectedDate) {
      params.date = selectedDate;
    }

    try {
      setIsLoading(true);
      const response = await liveDataService.getLiveData(params, token);
      // console.log(response);
      if (response.data.success) {
        const liveData = response.data.data;
        setAllData(liveData);
        setAvailableShifts(Object.keys(liveData));
        //showNotification(`Machine status fetched successfully`, "success");
      } else {
        showNotification(response.data.message, "error");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMachineData();
  }, [selectedMachine]);

  const handleSubTabChange = (tab) => {
    navigate(`?tab=${tab}`);
  };

  const dateChangeHandler = (e) => {
    setSelectedDate(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    fetchMachineData();
    console.log("submit");
  };

  let liveDataMinuteWise = [];
  if (allData[selectedShift] && allData[selectedShift]["minuteWisData"]) {
    liveDataMinuteWise = allData[selectedShift]["minuteWisData"];
  }

  let liveDataHourlyGrouped = [];
  if (allData[selectedShift] && allData[selectedShift]["hourlyGroupedData"]) {
    liveDataHourlyGrouped = allData[selectedShift]["hourlyGroupedData"];
  }

  return (
    <StyledPageContainer>
      <StyledPageHeader title="Machine wise data" />
      <Selection
        selectedMachine={selectedMachine}
        setSelectedMachine={setSelectedMachine}
        selectedDate={selectedDate}
        dateChangeHandler={dateChangeHandler}
        submitHandler={submitHandler}
        availableShifts={availableShifts}
        selectedShift={selectedShift}
        onShiftSelect={(shift) => setSelectedShift(shift)}
      />
      <SingleMachineCard machine={selectedMachine.value} token={token} />
      <TabsContainer>
        {React.Children.toArray(
          liveDataTabs.map((tab, i) => (
            <Tab
              isActive={activeSubTab === tab.value}
              onClick={() => handleSubTabChange(tab.value)}
            >
              {tab.name}
            </Tab>
          ))
        )}
      </TabsContainer>
      {activeSubTab === "overview" && (
        <Overview
          isLoading={isLoading}
          liveData={liveDataMinuteWise}
          groupedData={liveDataHourlyGrouped}
        />
      )}
      {activeSubTab === "hourlyTrend" && (
        <HourlyTrend isLoading={isLoading} groupedData={liveDataHourlyGrouped} />
      )}
      {activeSubTab === "rejection" && (
        <Rejection isLoading={isLoading} groupedData={liveDataHourlyGrouped} />
      )}
      {/*{activeSubTab === "idletime" && (
        <Idletime isLoading={isLoading} groupedData={groupedData} />
      )}
      {activeSubTab === "production" && <Production />} */}
    </StyledPageContainer>
  );
};

export default MachineStatus;
