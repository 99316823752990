import axios from './axios'

const liveDataService = {
  getLiveData: async (params, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/unit-4/live-data-machine`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      )
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
  getProductionRecords: async (params, token) => {
    try {
      const response = await axios.get(`/unit-4/production-records`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      return response
    } catch (err) {
      console.log(err)
      return err.response
    }
  },
}

export default liveDataService
