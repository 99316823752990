import React, { useState } from "react";

const getColor = (reason) => {
  const map = {
    ST0: "lightgreen",
    ST1: "#541388",
    ST2: "#d90368",
    ST3: "#f1e9da",
    ST4: "#ffd400",
    ST5: "#f7f7ff",
    ST6: "#c49991",
    ST7: "#B9D6F2",
    ST8: "#061A40",
    ST9: "#0353A4",
    ST10: "#0353A4",
  };

  return map[reason] || "red";
};

const getIdleReson = (reason) => {
  const map = {
    ST0: "Running",
    ST1: "No load",
    ST2: "No operator",
    ST3: "No tool",
    ST4: "No power",
    ST5: "Setting approval",
    ST6: "Tool change / Under setting",
    ST7: "Under maintenance",
    ST8: "Washroom / Water",
    ST9: "Breakfast / Snack",
    ST10: "Lunch / Dinner",
    ST11: "Tea break",
    ST12: "Machine cleaning",
  };

  return map[reason] || "Not selected";
};

const LiveDataBar = ({ liveData, width, count, isBorder }) => {
  const [isData, setIsData] = useState(false);
  //const [cordinates, setCordinates] = useState({});

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          maxWidth: `${width}px`,
          marginBottom: "16px",
        }}
      >
        {React.Children.toArray(
          liveData.map((data) => (
            <div
              // onMouseMove={(e) => {
              //   setCordinates({
              //     x: e.clientX,
              //     y: e.clientY,
              //   });
              // }}
              onMouseEnter={(e) => {
                setIsData(data);
              }}
              onMouseLeave={() => {
                setIsData({});
                //setCordinates({});
              }}
              style={{
                position: "relative",
                width: `${width / count}px`,
                height: "100px",
                backgroundColor: getColor(data.idleReason),
                //   data.machineStatus === 1 ? "red" : "lightgreen",
                // //border: isBorder ? "0.2px solid grey" : "none",
                cursor: "pointer",
              }}
            ></div>
          ))
        )}
      </div>
      <div
        style={
          {
            // position: "absolute",
            //left: `${cordinates.x}px`,
            //top: `${cordinates.y}px`,
          }
        }
      >
        <div>Time: {isData.time || "Hover on the chart"}</div>
        <div> Reason: { getIdleReson(isData.idleReason) || "Hover on the chart"}</div>
        <div>
          machineStatus: {isData.machineStatus === 0 ? "Running" : "Idle"}
        </div>
      </div>
    </div>
  );
};

export default LiveDataBar;

{
  /* 
  <textarea rows={100} contentEditable={true}>
    {JSON.stringify(liveData, null, 2)}
  </textarea> 
*/
}

// {Object.keys(isData).length !== 0 && isBorder && (
//   <div
//     style={
//       {
//         // position: "absolute",
//         //left: `${cordinates.x}px`,
//         //top: `${cordinates.y}px`,
//       }
//     }
//   >
//     <div>Time: {isData.time}</div>
//     <div> Reason: {isData.idleReason}</div>
//     <div>
//       machineStatus: {isData.machineStatus === 0 ? "Running" : "Idle"}
//     </div>
//   </div>
// )}
