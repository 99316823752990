import React from "react";

import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

const DivInputField = styled("div")(({ theme,  isMargin}) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: isMargin ? "32px" : "0",
  // marginTop: "32px",
  width: "100%",
}));

const DivInputLabelBox = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: "8px",
}));

const TypographyInputLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
}));

const DivInputBox = styled("div")(({ theme }) => ({
  display: "flex",
}));

const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  minHeight: "46px",
  marginBottom: "0px",
  padding: "16px 24px",
  border: "1px solid #a8a8b5",
  borderRadius: "8px",
  backgroundColor: "#f9fafc",
  boxShadow: "0 2px 7px 0 rgb(18 18 19 / 6%)",

  transition:
    "box-shadow 300ms ease, color 300ms ease, border-color 300ms ease",

  fontSize: "16px",
  lineHeight: " 20px",

  "&:hover": {
    borderColor: "#a8a8b5",
    boxShadow: "0 2px 12px 0 rgb(18 18 19 / 10%)",
  },

  "&:focus": {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    outline: 0,
  },
}));

const StyledTextFieldWithLabel = (props) => {
  const {
    label,
    placeholderText,
    textValue,
    onChangeHandler,
    onBlurHandler,
    isDisabled,
    type,
    isMargin,
  } = props;

  return (
    <DivInputField isMargin={isMargin}>
      <DivInputLabelBox>
        <TypographyInputLabel variant="body1">{label}</TypographyInputLabel>
      </DivInputLabelBox>
      <DivInputBox>
        <StyledInput
          type={type}
          placeholder={placeholderText}
          value={textValue}
          onChange={onChangeHandler}
          disabled={isDisabled || false}
          //onBlur={onBlurHandler}
        />
      </DivInputBox>
    </DivInputField>
  );
};

export default StyledTextFieldWithLabel;
