import React from "react";
import styled from "@emotion/styled/macro";

//icons
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? "black" : "#828282")};
`;

const CustomRadio = ({ title, condition, onSelect }) => {
  return (
    <Container onClick={onSelect} isActive={condition}>
      {condition ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
      <p>{title}</p>
    </Container>
  );
};

export default CustomRadio;
