import React, { useState, useEffect } from 'react'
import {
  useErrorTokenLoading,
  loading,
  success,
  failure,
} from '../../context/ErrorTokenLoadingContext'

import InputHeader from '../../components/Input/InputHeader'
import Loader from '../../components/Loader/Loader'
import BarChart from '../../components/Charts/BarChart/BarChart'
import TableWrapper from '../../components/TableWrapper/TableWrapper'
import OpwiseTable from '../../components/OpwiseTable/OpwiseTable'
import VariantwiseTable from '../../components/VariantwiseTable/VariantwiseTable'
import AchieversTable from '../../components/AchieversTable/AchieversTable'
import RejectionIdleInfo from '../../components/RejectionIdleInfo/RejectionIdleInfo'
import IdleTimeCard from '../../components/IdleTimeDoughnut/IdleTimeCard'
import RejectionCard from '../../components/RejectionDoughnut/RejectionCard'
import DashBoardAnalytics from '../../components/DashBoardAnalytics/DashBoardAnalytics'
import ProductionChart from '../../components/ProductionChart/ProductionChart'
import ProductionTable from '../../components/ProductionTable/ProductionTable'
import OeeChart from '../../components/OeeTrendChart/OeeChart'

import httpApi from '../../api/httpApi'

import * as DATE_UTILS from '../../services/date.utils'
import * as CHART_UTILS from '../../components/Charts/BarChart/BarChart.constant'

const MyMachineDashboard = () => {
  const { token, elState, elDispatch } = useErrorTokenLoading()

  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate())
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate())
  const [dashboardData, setDashboardData] = useState({})

  useEffect(() => {
    document.title = 'DPS | Dashboard'
  }, [])

  useEffect(() => {
    const fetchMyAPI = async () => {
      elDispatch(loading())
      try {
        const response = await httpApi.getData(token, '/dashboard', {
          currentDate: workingDate,
        })
        setDashboardData(response)
        elDispatch(success())
      } catch (err) {
        console.error(err.response)
        elDispatch(failure(err.response))
      }
    }
    fetchMyAPI()
  }, [workingDate, token])

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    setWorkingDate(calenderDate)
  }

  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
  }

  return (
    <div className="max-container">
      <InputHeader
        date={calenderDate}
        header="Dashboard"
        dateChangeHandler={dateChangeHandler}
        submitHandler={submitHandler}
        prevNextHandler={prevNextHandler}
      />
      {elState.loading && <Loader />}
      {!elState.loading && (
        <>
          <DashBoardAnalytics data={dashboardData.productionTable} />
          {/* {dashboardData.productionTable && (
            <ProductionChart
              monthData={dashboardData.productionTable.prodTrendMonth}
              yearData={dashboardData.productionTable.prodTrendYear}
            />
          )} */}
          {dashboardData.productionTable && (
            <ProductionTable
              productionData={dashboardData.productionTable.prodTableData}
            />
          )}

          {dashboardData.oeeTrend && (
            <OeeChart
              monthData={dashboardData.oeeTrend.oeeTrendMonth}
              yearData={dashboardData.oeeTrend.oeeTrendYear}
            />
          )}

          {/* <BarChart
            chartTitle={chartTitle}
            chartLabels={chartLabels}
            dataGroup={dataGroup}
            iconClass='fa fa-line-chart'
            xLabel='DAYS'
            yLabel='OEE'
          /> */}
          <TableWrapper
            cardTitle="Operationwise Production Quantity"
            //iconClass="fa fa fa-cubes"
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {dashboardData['prodQtyOpwise'] && (
                  <OpwiseTable
                    opData={dashboardData['prodQtyOpwise']['monthlyData']}
                    tableTitle="Month"
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {dashboardData['prodQtyOpwise'] && (
                  <OpwiseTable
                    opData={dashboardData['prodQtyOpwise']['dailyData']}
                    tableTitle="Day"
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <TableWrapper
            cardTitle="Variantwise Production Quantity"
            //iconClass="fa fa fa-cubes"
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {dashboardData['prodQtyVariantwise'] && (
                  <VariantwiseTable
                    variantData={
                      dashboardData['prodQtyVariantwise']['monthlyData']
                    }
                    tableTitle="Month"
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {dashboardData['prodQtyVariantwise'] && (
                  <VariantwiseTable
                    variantData={
                      dashboardData['prodQtyVariantwise']['dailyData']
                    }
                    tableTitle="Day"
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <TableWrapper cardTitle="Achievers" iconClass="fa fa-trophy ">
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {dashboardData['achieversData'] && (
                  <AchieversTable
                    achieversData={dashboardData['achieversData']['shift1']}
                    tableTitle="Shift-1"
                    isAchiever={true}
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {dashboardData['achieversData'] && (
                  <AchieversTable
                    achieversData={dashboardData['achieversData']['shift2']}
                    tableTitle="Shift-2"
                    isAchiever={true}
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <TableWrapper
            cardTitle="Non-Achievers"
            iconClass="fa fa-sort-amount-desc"
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {dashboardData['achieversData'] && (
                  <AchieversTable
                    achieversData={dashboardData['achieversData']['shift1']}
                    tableTitle="Shift-1"
                    isAchiever={false}
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {dashboardData['achieversData'] && (
                  <AchieversTable
                    achieversData={dashboardData['achieversData']['shift2']}
                    tableTitle="Shift-2"
                    isAchiever={false}
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <TableWrapper
            cardTitle="Data From Daily Report"
            //iconClass="fa fa-file-text-o"
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {dashboardData['rejectionIdleTime'] && (
                  <RejectionIdleInfo
                    data={dashboardData['rejectionIdleTime']['rejection']}
                    tableTitle="Rejection Data"
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {dashboardData['rejectionIdleTime'] && (
                  <RejectionIdleInfo
                    data={dashboardData['rejectionIdleTime']['idle_time']}
                    tableTitle="Idle-Time Data (In Minutes)"
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <div className="flex-container">
            <div className="flex-item-2 mb-0">
              {dashboardData['rejectionData'] && (
                <RejectionCard
                  rejectionData={dashboardData['rejectionData']}
                  rejectionTotal={dashboardData['rejectionIdleTime']}
                />
              )}
            </div>
            <div className="flex-item-2 mb-0">
              {dashboardData['idleTimeData'] && (
                <IdleTimeCard
                  idleTimeData={dashboardData['idleTimeData']}
                  idleTimeTotal={dashboardData['rejectionIdleTime']}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MyMachineDashboard
