import React, { useState, useEffect } from "react";
import {useErrorTokenLoading, loading, failure, success} from "../../context/ErrorTokenLoadingContext";

import InputHeader from "../../components/Input/InputHeader";
import Loader from "../../components/Loader/Loader";
import BarChart from "../../components//Charts/BarChart/BarChart";
import MachineShopInfoCard from "../../components/MachineShopInfoCard/MachineShopInfoCard";

import httpApi from "../../api/httpApi";

import * as DATE_UTILS from "../../services/date.utils";
import * as CHART_UTILS from "../../components/Charts/BarChart/BarChart.constant";

const ProductionReport = () => {

  const {token, elState, elDispatch} = useErrorTokenLoading();

  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate());
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate());
  const [prodInfoData, setProdInfoData] = useState({});

  useEffect(() => {
    const fetchMyAPI = async () => {
      try {
        elDispatch(loading());
        const response = await httpApi.getData(token, '/prodInfo', { currentDate: workingDate});
        //console.log(response);
        setProdInfoData(response);
        elDispatch(success());
      } catch (err) {
        elDispatch(failure(err.response));
        //console.error(err);
      }
    };
    fetchMyAPI();
  }, [workingDate, token]);

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //setWorkingDate(event.target.form.queryDate.value);
    setWorkingDate(calenderDate);
  };

  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
  };

  useEffect(() => {
    document.title = `DPS | Production Report`;
  }, []);

  const chartTitle = prodInfoData["machineInfo"]
    ? `Production Information`
    : "";

  const chartLabels = prodInfoData["machineInfo"]
    ? prodInfoData["machineInfo"].map((el) => el["machine"])
    : [];

  const dataGroup = prodInfoData["machineInfo"]
    ? [
        {
          ...CHART_UTILS.BAR,
          label: "Shift1-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["s1_qty"]),
          backgroundColor: "#7afade",
          borderColor: "#04b890",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Shift1-Target",
          data: prodInfoData["machineInfo"].map((el) => el["s1_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          pointHoverRadius:5,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
          },
        {
          ...CHART_UTILS.BAR,
          label: "Shift2-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["s2_qty"]),
          backgroundColor: "#f5ae67",
          borderColor: "#b55d05",
        },
        {
          label: "Shift2-Target",
          data: prodInfoData["machineInfo"].map((el) => el["s2_target"]),
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          pointHoverRadius:5,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["daily_qty"]),
          backgroundColor: "#fa9ba6",
          borderColor: "#fa465b",
        },
        {
          label: "Day-Target",
          data: prodInfoData["machineInfo"].map((el) => el["day_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          pointHoverRadius:5,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
        }
      ]
    : [];

  return (
    <div className="max-container">
      <InputHeader
        date={calenderDate}
        header="Production Report"
        submitHandler={submitHandler}
        dateChangeHandler={dateChangeHandler}
        prevNextHandler={prevNextHandler}
      />

      {elState.loading && <Loader />}
      {!elState.loading && (
        <>
          <BarChart
            chartTitle={chartTitle}
            chartLabels={chartLabels}
            dataGroup={dataGroup}
            iconClass="fa fa-bar-chart"
            xLabel="DAYS"
            yLabel="ACHIEVED QUANTITY"
          />
          <div className="flex-container">
            {prodInfoData["machineShopInfo"] &&
              Object.keys(prodInfoData["machineShopInfo"]).map((key) => (
                <div key={key} className="flex-item-1">
                  <MachineShopInfoCard
                    machine={key}
                    key={key}
                    data={prodInfoData["machineShopInfo"][key]}
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductionReport;


// [
//   {
//     ...CHART_UTILS.BAR,
//     label: "Shift1-Achieved",
//     data: prodInfoData["machineInfo"].map((el) => el["s1_qty"]),
//     backgroundColor: "#7afade",
//     borderColor: "#04b890",
//   },
// {
//   label: "Shift1-Target",
//   data: prodInfoData["machineInfo"].map((el) => el["s1_target"]),
//   pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
//   type: "line",
//   backgroundColor: "#7afade",
//   //fill: "start",
//   // pointRadius: 0,
//   pointStyle: "circle",
//   pointBackgroundColor: "rgba(0, 0, 0, 0)",
//   pointBorderColor: "rgba(0, 0, 0, 0)"
// },
//   {
//     ...CHART_UTILS.BAR,
//     label: "Shift2-Achieved",
//     data: prodInfoData["machineInfo"].map((el) => el["s2_qty"]),
//     backgroundColor: "#f5ae67",
//     borderColor: "#b55d05",
//   },
// {
//   label: "Shift2-Target",
//   data: prodInfoData["machineInfo"].map((el) => el["s2_target"]),
//   pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
//   type: "line",
//   backgroundColor: "#3A8B9C",
//  //fill: "start",
//   // pointRadius: 0,
//   pointStyle: "circle",
//   pointBackgroundColor: "rgba(0, 0, 0, 0)",
//   pointBorderColor: "rgba(0, 0, 0, 0)"
// },
//   {
//     ...CHART_UTILS.BAR,
//     label: "Day-Achieved",
//     data: prodInfoData["machineInfo"].map((el) => el["daily_qty"]),
//     backgroundColor: "#fa9ba6",
//     borderColor: "#fa465b",
//   },
// {
//   label: "Day-Target",
//   data: prodInfoData["machineInfo"].map((el) => el["day_target"]),
//   pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
//   type: "line",
//   backgroundColor: "#fa9ba6",
//   //fill: "start",
//   // pointRadius: 0,
//   pointStyle: "circle",
//   pointBackgroundColor: "rgba(0, 0, 0, 0)",
//   pointBorderColor: "rgba(0, 0, 0, 0)"
// }
// ]
