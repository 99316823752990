import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import Layout from './layouts/Layout'
import Loader from './components/Loader/Loader'
import MainPage from './pages/MainPage/MainPage'
import MachineDashboard from './pages/MachineDashboard/MachineDashboard'
import MachineShopDemography from './pages/MachineShopDemography/MachineShopDemography'
import MachineWiseReport from './pages/MachineWiseReport/MachineWiseReport'
import ProductionReport from './pages/ProductionReport/ProductionReport'
import IdleTimeReport from './pages/IdleTimeReport/IdleTimeReport'
import RejectionReport from './pages/RejectionReport/RejectionReport'
import OperatorReport from './pages/OperatorReport/OperatorReport'
import NotFound from './pages/NotFound/NotFound'

import Workspace from './pages/Workspace/Workspace'
import MachineLiveData from './pages/MachineLiveData/MachineLiveData'
import MachineWiseData from './pages/MachineWiseData/MachineWiseData'
import Dashboard from './pages/Dashboard/Dashboard'
import ProductionRecord from './pages/ProductionRecords/ProductionRecord'
import EmployeeManagement from './pages/EmployeeManagement/EmployeeManagement'
import PartManagement from './pages/PartManagement/PartManagement'
import HmiUpdates from './pages/Updates/HMIUpdates'
import CreateConfig from './pages/CreateConfig/CreateConfig'

//context
import ToastContext from './context/ToastContext'

//styles and assets
import inputGlobalStyles from './theme/GlobalStyles'
import { lightTheme } from './theme/theme'
import './App.css'

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect()
    return <Loader />
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        {inputGlobalStyles}
        <ToastContext />
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='unit-1' element={<Layout />}>
            <Route path='dashboard' element={<MachineDashboard />} />
            <Route path='demography' element={<MachineShopDemography />} />
            <Route path='demography/:mcName' element={<MachineWiseReport />} />
            <Route path='prodreport' element={<ProductionReport />} />
            <Route path='idletime' element={<IdleTimeReport />} />
            <Route path='rejection' element={<RejectionReport />} />
            <Route path='operatorinfo' element={<OperatorReport />} />
          </Route>
          <Route path='unit-4' element={<Workspace />}>
            <Route path='live-data' element={<MachineLiveData />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='machine-wise' element={<MachineWiseData />} />
            <Route path='production-record' element={<ProductionRecord />} />
            <Route path='config' element={<CreateConfig />} />
            <Route path='employee-management' element={<EmployeeManagement />} />
            <Route path='part-management' element={<PartManagement />} />
            <Route path='hmi-update' element={<HmiUpdates />} />
          </Route>
          <Route path='*' element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </>
  )
}

export default App
