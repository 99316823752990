import React from 'react'
import RadialGauge from '../RadialGauge/RadialGauge'
import "./AnalyticsCard.css"

const AnalyticsCard = (props) => {

  const {cardName, gaugeValue, totalLabel, totalValue, actualLabel, actualValue}  = props;
  return (
    <div className='container shadow'>
      <div className='flex-container'>
        <div className='gauge-item-2'>
          <h4 className='h4-margin ml-sm'>{cardName}</h4>
          <hr className='ml-sm mr-md' />
          <table className='ml-xs'>
            <tbody>
              <tr>
                <td className='mw-15'>
                  <span className='dot1 orange'></span>
                </td>
                <td className='mw-md'>{totalLabel}</td>
                <td className='bold'>{totalValue}</td>
              </tr>
              <tr>
                <td className='mw-15'>
                  <span className="dot1 red"></span>
                </td>
                <td className='mw-md'>{actualLabel}</td>
                <td className='bold'>{actualValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <RadialGauge value={gaugeValue} />
      </div>
    </div>
  )
}

export default AnalyticsCard
