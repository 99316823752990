export const MACHINES_1 = [
  "digvijaya",
  "pavanaputra",
  "dasharatha",
  "anantha",
  "krishna",
  "vijaya",
  "shriManjunatha",
  "vikrama",
  "trivikrama",
  "vishnu",
  "subrahmanya",
];

export const MACHINES = [
{label: 'Digvijaya', value: 'digvijaya'},
{label: 'Pavanaputra', value: 'pavanaputra'},
{label: 'Dasharatha', value: 'dasharatha'},
{label: 'Anantha', value: 'anantha'},
{label: 'Krishna', value: 'krishna'},
{label: 'Vijaya', value: 'vijaya'},
{label: 'Shri Manjunatha', value: 'shriManjunatha'},
{label: 'Vikrama', value: 'vikrama'},
{label: 'Trivikrama', value: 'trivikrama'},
{label: 'Vishnu', value: 'vishnu'},
{label: 'Subrahmanya', value: 'subrahmanya'}
]

export const ROLES = [
  {
    value: "operator",
    label: "Operator",
  },
  {
    value: "lineInspector",
    label: "line Inspector",
  },
  {
    value: "supervisor",
    label: "Supervisor",
  },
];
