import React, { useState, useEffect } from "react";
import { useParams, useNavigate  } from "react-router-dom";
import {useErrorTokenLoading, loading, failure, success} from "../../context/ErrorTokenLoadingContext";

import InputHeader from "../../components/Input/InputHeader";
import Loader from "../../components/Loader/Loader";
import BarChart from "../../components//Charts/BarChart/BarChart";
import TableWrapper from "../../components/TableWrapper/TableWrapper";
import PartwiseProdInfo from "../../components/PartwiseProdInfo/PartwiseProdInfo";
import OperatorwiseProdInfo from "../../components/OperatorwiseProdInfo/OperatorwiseProdInfo";
import RejectionIdleInfo from "../../components/RejectionIdleInfo/RejectionIdleInfo";
import IdleTimeCard from "../../components/IdleTimeDoughnut/IdleTimeCard";
import RejectionCard from "../../components/RejectionDoughnut/RejectionCard";

import httpApi from "../../api/httpApi";

import * as DATE_UTILS from "../../services/date.utils";
import * as CHART_UTILS from "../../components/Charts/BarChart/BarChart.constant";

const MachineWiseReport = () => {

  const {token, elState, elDispatch} = useErrorTokenLoading();
  const navigate = useNavigate()
  const { mcName } = useParams();

  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate());
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate());
  const [machineList, setMachineList] = useState([])
  const [currentMachine,setCurrentMachine] = useState(mcName);
  const [machineData, setMachineData] = useState({});

  useEffect(() => {
    document.title = `DPS | ${currentMachine.toUpperCase()}`;
    navigate(`/unit-1/demography/${currentMachine.toLowerCase()}`)
  }, [currentMachine,navigate])

  useEffect(() => {
    const fetchMyAPI = async () => {
      try {
        elDispatch(loading());
        const { machineListData } = await httpApi.getData(token, '/machinelist', {});
        //console.log(machineListData);
        setMachineList(machineListData);
        elDispatch(success());
      } catch (err) {
        elDispatch(failure(err.response));
        //console.error(err);
      }
    };
    fetchMyAPI();
  }, []);

  useEffect(() => {
    const fetchMyAPI = async () => {
      try {
        elDispatch(loading());
        const response = await httpApi.getData(token, `/demography/${currentMachine}`, { currentDate: workingDate,  machine: currentMachine});
        //console.log(response);
        setMachineData(response);
        elDispatch(success());
      } catch (err) {
        elDispatch(failure(err.response));
        //console.error(err);
      }
    };
    fetchMyAPI();
  }, [workingDate, currentMachine, token]);

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value);
  };

  const submitHandler = ( e ) => {
    e.preventDefault();
    //setWorkingDate(e.target.form.queryDate.value);
    setWorkingDate(calenderDate);
  };

  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate))
  };

  const machineChangeHandler = (event) => {
    setCurrentMachine(event.target.value)
  }

  const oeeChartTitle = machineData["oeeTrend"] ? `OEE Trend` : '';
  
  const achQtyChartTitle = machineData["achievedQtyTrend"]? `Achieved Quantity Trend`: '';

  const oeeChartLabels = machineData["oeeTrend"]
    ? machineData['oeeTrend'].map((el) => el["date_of_job"])
    : [];

  const achQtyChartLabels = machineData["achievedQtyTrend"]
    ? machineData['achievedQtyTrend'].map((el) => el["date_of_job"])
    : [];

  const oeeDataGroup = machineData["oeeTrend"]
    ? [
        {
          ...CHART_UTILS.BAR,
          label: "Shift-1",
          data: machineData["oeeTrend"].map((el) => el["s1_oee"]),
          backgroundColor: "#7afade",
          borderColor: "#04b890",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Shift-2",
          data: machineData["oeeTrend"].map((el) => el["s2_oee"]),
          backgroundColor: "#f5ae67",
          borderColor: "#b55d05",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day",
          data: machineData["oeeTrend"].map((el) => el["daily_oee"]),
          backgroundColor: "#fa9ba6",
          borderColor: "#fa465b",
        },
      ]
    : [];

  const achQtyDataGroup = machineData["oeeTrend"]
    ? [
        {
          ...CHART_UTILS.BAR,
          label: "Shift1-Achieved",
          data: machineData["achievedQtyTrend"].map((el) => el["s1_qty"]),
          backgroundColor: "#7afade",
          borderColor: "#04b890",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Shift1-Target",
          data: machineData["achievedQtyTrend"].map((el) => el["s1_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          pointHoverRadius:5,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
          },
        {
          ...CHART_UTILS.BAR,
          label: "Shift2-Achieved",
          data: machineData["achievedQtyTrend"].map((el) => el["s2_qty"]),
          backgroundColor: "#f5ae67",
          borderColor: "#b55d05",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Shift2-Target",
          data: machineData["achievedQtyTrend"].map((el) => el["s2_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          pointHoverRadius:5,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
          },
        {
          ...CHART_UTILS.BAR,
          label: "Day-Achieved",
          data: machineData["achievedQtyTrend"].map((el) => el["daily_qty"]),
          backgroundColor: "#fa9ba6",
          borderColor: "#fa465b",
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day-Target",
          data: machineData["achievedQtyTrend"].map((el) => el["daily_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          pointHoverRadius:5,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
          },
      ]
    : [];

  return (
    <div className="max-container">
      {/* <ErrorComp message={error}/> */}
      <InputHeader
        date={calenderDate}
        dateChangeHandler={dateChangeHandler}
        submitHandler={submitHandler}
        prevNextHandler={prevNextHandler}
        machineList={machineList}
        currentMachine={currentMachine}
        machineChangeHandler={machineChangeHandler}
      />
      {elState.loading && <Loader />}
      {!elState.loading &&  (
        <>
          <BarChart
            chartTitle={oeeChartTitle}
            chartLabels={oeeChartLabels}
            dataGroup={oeeDataGroup}
            iconClass="fa fa-line-chart"
            xLabel="DAYS"
            yLabel="OEE"
          />
          <BarChart
            chartTitle={achQtyChartTitle}
            chartLabels={achQtyChartLabels}
            dataGroup={achQtyDataGroup}
            iconClass="fa fa-line-chart"
            xLabel="DAYS"
            yLabel="ACHIEVED QUANTITY"
          />
          <TableWrapper
            cardTitle="Production Info Shift-1"
            iconClass="fa fa-file-text-o"
            shiftTarget={machineData["achievedQtyData"] && machineData["achievedQtyData"]["target"]["shift1_target"]}
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {machineData["achievedQtyData"] && (
                  <OperatorwiseProdInfo
                    data={
                      machineData["achievedQtyData"]["shift1"]["operatordata"]
                    }
                    tableTitle="Operators Data"
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {machineData["achievedQtyData"] && (
                  <PartwiseProdInfo
                    data={
                      machineData["achievedQtyData"]["shift1"]["partwiseData"]
                    }
                    tableTitle="Partwise Data"
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <TableWrapper
            cardTitle="Production Info Shift-2"
            iconClass="fa fa-file-text-o"
            shiftTarget={ machineData["achievedQtyData"] && machineData["achievedQtyData"]["target"]["shift2_target"]}
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {machineData["achievedQtyData"] && (
                  <OperatorwiseProdInfo
                    data={
                      machineData["achievedQtyData"]["shift2"]["operatordata"]
                    }
                    tableTitle="Operators Data"
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {machineData["achievedQtyData"] && (
                  <PartwiseProdInfo
                    data={
                      machineData["achievedQtyData"]["shift2"]["partwiseData"]
                    }
                    tableTitle="Partwise Data"
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <TableWrapper
            cardTitle="Data From Daily Report"
            //iconClass="fa fa-file-text-o"
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {machineData["rejectionIdleTime"] && (
                  <RejectionIdleInfo
                    data={machineData["rejectionIdleTime"]["rejection"]}
                    tableTitle="Rejection Data"
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {machineData["rejectionIdleTime"] && (
                  <RejectionIdleInfo
                    data={machineData["rejectionIdleTime"]["idle_time"]}
                    tableTitle="Idle-Time Data (In Minutes)"
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <div className="flex-container">
            <div className="flex-item-2 mb-0">
              {machineData["rejectionData"] && (
                <RejectionCard rejectionData={machineData["rejectionData"]} />
              )}
            </div>
            <div className="flex-item-2 mb-0">
              {machineData["idleTimeData"] && (
                <IdleTimeCard idleTimeData={machineData["idleTimeData"]} isForMachine={true} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MachineWiseReport;
