import React from "react";
import { styled } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Example = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Dot = styled("div")(({ theme, machineStatus }) => ({
  display: "inline-block",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  marginLeft: "10px",
  position: "relative",
  backgroundColor: machineStatus ? "lightgreen" : "red",
  transform: "scale(0.4)",
  "&::before": {
    content: '""',
    top: 0,
    left: 0,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "inherit",
    borderRadius: "50%",
    zIndex: "-1",
    animation: machineStatus ? `ripple 1.5s ease-out infinite` : '',
  },
  "@keyframes ripple": {
    from: {
      opacity: 1,
      transform: "scale(0)",
    },
    to: {
      opacity: 0,
      transform: "scale(3)",
    },
  },
}));

const RippleEffect = (props) => {
  console.log("status", props.machineStatus);

  return (
    <Root>
      <Example>
        <Dot machineStatus={props.machineStatus}/>
      </Example>
    </Root>
  );
};

export default RippleEffect;
