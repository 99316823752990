import React from 'react'
import { styled } from '@mui/material';

const DivContent = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
  color: theme.palette.primary.main,
  borderRadius: "16px",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 0px 9px 0px rgb(0 0 0 / 10%)",
}));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <DivContent>
          <h1>Something went wrong.</h1>
          <a href="/workspace/dashboard">Please go to home page</a>
        </DivContent>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
