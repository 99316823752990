import React from "react";
import { Button } from "@mui/material";
import ReactSelect from "react-select";

import FormTextField from "../../../components/FormTextField/FormTextField";
import StyledSelectField from "../../../components/StyledSelectField/StyledSelectField";

const timeData = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    height: "48px",
  }),
};

const ShiftConfigComp = ({ data, removeShift, updateValue }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "16px",
        marginTop: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        padding: "16px",
        boxShadow: "0 8px 28px 0 rgb(18 18 19 / 10%)",
      }}
    >
      <div style={{ flex: 1 }}>
        <FormTextField
          autoFocus={true}
          label="Shift"
          placeholderText="Enter Shift number.. Eg: 1"
          textValue={data.shift}
          inputType="number"
          isRequired={true}
          isDisabled={true}
          onChangeHandler={(e) => updateValue(data.id, "shift", e.target.value)}
        />
      </div>
      <div style={{ flex: 1 }}>
        <StyledSelectField
          label="Start time"
          placeHolderText="Start time"
          selectedValue={{ value: data.start, label: data.start }}
          selectOptions={timeData.map((time) => ({
            value: time,
            label: time.toString(),
          }))}
          onChangeHandler={(value) =>
            updateValue(data.id, "start", value.value)
          }
          isRequired={true}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
        />
      </div>
      <div style={{ flex: 1 }}>
        <StyledSelectField
          label="End time"
          placeHolderText="End time"
          selectedValue={{ value: data.end, label: data.end }}
          selectOptions={timeData.map((time) => ({
            value: time,
            label: time.toString(),
          }))}
          onChangeHandler={(value) => updateValue(data.id, "end", value.value)}
          isRequired={true}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
        />
      </div>
      <div style={{ flex: 1 }}>
        <FormTextField
          label="Break time"
          placeholderText="Enter breaktime in minutes Eg: 120"
          textValue={data.breakTime}
          inputType="number"
          isRequired={true}
          isDisabled={false}
          onChangeHandler={(e) =>
            updateValue(data.id, "breakTime", e.target.value)
          }
        />
      </div>

      <Button
        style={{ marginTop: "32px" }}
        variant="outlined"
        onClick={() => removeShift(data.id)}
      >
        Remove
      </Button>
    </div>
  );
};

export default ShiftConfigComp;
