import axios from "axios";

let baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: baseURL
});

const authorized = (token) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return instance;
}

const httpApi = {
  getData: async (token, path, paramsObject) => {
    const { data } = await authorized(token).get(path, {
      params: paramsObject,
    });
    return data;
  }
};

// import { dashboardData } from "./dashboard";
// import { demographyData } from "./demography";
// import { machineData } from "./machineData";
// import { prodInfoData } from "./prodInfoData";
// import { rejectionData } from "./rejection";
// import { idleTimeData } from "./idleTime";
// import { machineList } from "./machineList";
// import { operatorList } from "./operatorList";
// import { operatorData } from "./operator";

// const httpApi = {
//   getDashboardData: () =>  dashboardData,
//   getDemographyData: () => demographyData,
//   getMachineData: () => machineData,
//   getProdInfoData: () => prodInfoData,
//   getRejectionData: () => rejectionData,
//   getIdleTimeData: () => idleTimeData,
//   getOperatorData: () => operatorData,
//   getMachineList: () => machineList,
//   getOperatorList: () => operatorList,
  
// };

 export default httpApi;