import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useReducer,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

const initialState = {
  loading: false,
  error: null,
};

export const loading = () => {
  return {
    type: "LOADING",
  };
};

export const success = () => {
  return {
    type: "SUCCESS",
  };
};

export const failure = (err) => {
  return {
    type: "FAILURE",
    error: err,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "SUCCESS":
      return {
        loading: false,
        error: null,
      };
    case "FAILURE":
      return {
        loading: false,
        error: action.error,
      };
    default:
      throw new Error();
  }
}

const ErrorTokenLoading = createContext();

export function useErrorTokenLoading() {
  return useContext(ErrorTokenLoading);
}

const ErrorTokenLoadingContext = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        dispatch(loading());
        const token = await getAccessTokenSilently();
        setToken(token);
        dispatch(success());
      } catch (err) {
        dispatch(failure(err.response));
        console.log(err);
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  return (
    <ErrorTokenLoading.Provider
      value={{ token: token, elState: state, elDispatch: dispatch }}
    >
      {children}
    </ErrorTokenLoading.Provider>
  );
};
export default ErrorTokenLoadingContext;
