import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledPageHeader from '../../components/StyledPageHeader/StyledPageHeader'
import StyledTable from '../../components_u4/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'

import {
  useErrorTokenLoading,
  loading,
  failure,
  success,
} from '../../context/ErrorTokenLoadingContext'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'
import * as DATE_UTILS from '../../utils/date.utils'

//services
import liveDataService from '../../services/liveData.service'

const CustomForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginTop: '32px',
}))

const CustomInput = styled('input')(({ theme }) => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer',
  padding: '8px',
}))

const CustomButton = styled('button')(({ theme }) => ({
  height: '40px',
  margin: '2px',
  minWidth: '150px',
  borderRadius: '8px',
  cursor: 'pointer',
}))

const machineOptions = MACHINES

const ProductionRecord = () => {
  const { token } = useErrorTokenLoading()

  const defaultMachine = {
    value: 0,
    label: 'Select machine',
  }

  const [productionRecordRows, setProductionRecordRows] = useState([])
  const [selectedDate, setSelectedDate] = useState(DATE_UTILS.todaysDate())
  const [machine, setMachine] = useState(machineOptions[0])
  const [isLoading, setIsLoading] = useState(false)

  const fetchProductionRecords = async () => {
    const params = {}

    if (selectedDate) {
      params.date = selectedDate
    }

    if (machine.value !== 0) {
      params.machine = machine.value
    }

    try {
      setIsLoading(true)
      const productionRecordResponse =
        await liveDataService.getProductionRecords(params, token)
      console.log(productionRecordResponse)
      const data = productionRecordResponse.data.data
      const partsData = data.map((data, i) => {
        return {
          index: i + 1,
          machine: data.machine,
          shift: `S-${data.shift}`,
          partName: data.partName,
          partType: data.partType,
          palletCount: data.palletCount,
          cycleTime: data.cycleTime,
          workOrderNum: data.workOrderNum,
          quantity: data.quantity,
          startTime: data.startTime,
          partTarget: data.partTarget,
          timeTarget: data.timeTarget,
        }
      })
      console.log(partsData)
      setProductionRecordRows(partsData)
    } catch (err) {
      console.log(err)
      setProductionRecordRows([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (machine === null) {
      return
    }
    fetchProductionRecords()
  }, [machine])

  const clearAll = async () => {
    setSelectedDate(DATE_UTILS.todaysDate())
    setMachine(machineOptions[0])
    await fetchProductionRecords()
  }

  const dateChangeHandler = (e) => {
    setSelectedDate(e.target.value)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    fetchProductionRecords()
  }

  const productionRecordColumns = [
    {
      Header: 'S.No',
      accessor: 'index',
    },
    {
      Header: 'Machine',
      accessor: 'machine',
    },
    {
      Header: 'Shift',
      accessor: 'shift',
    },
    {
      Header: 'Work order',
      accessor: 'workOrderNum',
    },
    {
      Header: 'Part number',
      accessor: 'partName',
    },
    {
      Header: 'Operation',
      accessor: 'partType',
    },
    {
      Header: 'Parts / cycle',
      accessor: 'palletCount',
    },
    {
      Header: 'Cycle time(sec)',
      accessor: 'cycleTime',
    },

    {
      Header: 'Input quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Start time',
      accessor: 'startTime',
    },
    {
      Header: 'Part target',
      accessor: 'partTarget',
    },

    {
      Header: 'Time target(min)',
      accessor: 'timeTarget',
    },
  ]

  if (isLoading) {
    return <div>Loading.....</div>
  }

  return (
    <StyledPageContainer>
      <StyledPageHeader title='Production record' />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-between',
          alignItems: 'center',
          columnGap: '16px',
          padding: '16px 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-between',
            marginLeft: 'auto',
            columnGap: '16px',
            padding: '16px 0',
          }}
        >
          <Button
            startIcon={<ClearIcon />}
            variant='contained'
            onClick={clearAll}
          >
            Clear
          </Button>
        </div>
      </div>
      <Stack
        spacing={2}
        direction='row'
        sx={{ marginBottom: '16px', width: '100%', alignItems: 'center' }}
      >
        <CustomForm>
          <CustomInput
            id='queryDate'
            type='date'
            value={selectedDate}
            min='2021-01-15'
            max={DATE_UTILS.maxDate()}
            onChange={dateChangeHandler}
          />
          <CustomButton onClick={submitHandler}>Submit</CustomButton>
        </CustomForm>
        <StyledSelectField
          label='Machine'
          placeHolderText='Select machine'
          selectedValue={machine}
          selectOptions={machineOptions}
          onChangeHandler={(value) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage='No machine found'
        />
      </Stack>

      <StyledTableContainer>
        {!isLoading && productionRecordRows.length === 0 && (
          <StyledEmptyHeader>There are no production record</StyledEmptyHeader>
        )}
        {productionRecordRows.length > 0 && (
          <StyledTable
            pagination={true}
            columns={productionRecordColumns}
            data={productionRecordRows}
            hiddenColumns={['machine']}
          />
        )}
      </StyledTableContainer>
    </StyledPageContainer>
  )
}

export default ProductionRecord
