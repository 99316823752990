import React, { useState } from 'react'
import BarChart from '../Charts/BarChart/BarChart'
import * as CHART_UTILS from '../Charts/BarChart/BarChart.constant'

const ProductionChart = (props) => {
  const { monthData, yearData } = props
  const [timePeriod, setTimePeriod] = useState('date')

  const chartTitle = 'Production Trend'
  let chartLabels = []
  let achievedDay = []
  let achievedNight = []
  let achievedTotal = []
  let targetDay = []
  let targetNight = []
  let targetTotal = []

  const prepareChartData = (data, timePeriod) => {
    chartLabels = data.map((el) => el[timePeriod])
    achievedDay = data.map((el) => el['shift1Achieved'])
    achievedNight = data.map((el) => el['shift2Achieved'])
    achievedTotal = data.map((el) => el['totalAchieved'])

    targetDay = data.map((el) => el['shift1Target'])
    targetNight = data.map((el) => el['shift2Target'])
    targetTotal = data.map((el) => el['totalTarget'])
  }

  prepareChartData(timePeriod === 'date' ? monthData : yearData, timePeriod)

  const dataGroup = [
    {
      ...CHART_UTILS.BAR,
      label: 'S-1 Achieved',
      data: achievedDay,
      backgroundColor: '#7afade',
      borderColor: '#04b890',
    },
    {
      ...CHART_UTILS.BAR,
      label: 'S-1 Target',
      data: targetDay,
      type: 'line',
      fill: false,
      backgroundColor: '#7afade',
      borderColor: '#04b890',
      borderWidth: 1,
      tension: 0,
      pointRadius: 2,
      pointHoverRadius: 5,
    },
    {
      ...CHART_UTILS.BAR,
      label: 'S-2 Achieved',
      data: achievedNight,
      backgroundColor: '#f5ae67',
      borderColor: '#b55d05',
    },
    {
      ...CHART_UTILS.BAR,
      label: 'S-2 Target',
      data: targetNight,
      type: 'line',
      fill: false,
      backgroundColor: '#f5ae67',
      borderColor: '#b55d05',
      borderWidth: 1,
      tension: 0,
      pointRadius: 2,
      pointHoverRadius: 5,
    },
    {
      ...CHART_UTILS.BAR,
      label: 'Day Achieved',
      data: achievedTotal,
      backgroundColor: '#fa9ba6',
      borderColor: '#fa465b',
    },
    {
      ...CHART_UTILS.BAR,
      label: 'Day Target',
      data: targetTotal,
      type: 'line',
      fill: false,
      backgroundColor: '#fa9ba6',
      borderColor: '#fa465b',
      borderWidth: 1,
      tension: 0,
      pointRadius: 2,
      pointHoverRadius: 5,
    },
  ]

  return (
    <div className='card-container flex-row border-blue mt-24 shadow'>
      <div className='card-header bg-light'>
        <h3>{chartTitle}</h3>
        <div>
          <select
            className='select'
            value={timePeriod}
            onChange={(event) => setTimePeriod(event.target.value)}
          >
            <option value='date'>Daily</option>
            <option value='month'>Monthly</option>
          </select>
        </div>
      </div>
      <div className='card-body'>
        <BarChart
          chartLabels={chartLabels}
          dataGroup={dataGroup}
          xLabel='DAYS'
          yLabel='Achieved Quantity'
        />
      </div>
    </div>
  )
}

export default ProductionChart
