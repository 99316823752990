import React, { useState, useEffect } from 'react'
import axios from 'axios'

//components
import RippleEffect from '../../../components/RippleEffect/RippleEffect'
import StyledTable from '../../../components_u4/ReactTable/StyledTable'
import StyledEmptyHeader from '../../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledTableContainer from '../../../components/StyledTableContainer/StyledTableContainer'

//context
import { useToast } from '../../../context/ToastContext'

//utils
import { capitalizeFirstLetter } from '../../../utils/string.utils'
import { showNotification } from '../../../utils/toast.utils'

const LiveDataTabular = (props) => {
  const [machineData, setMachineData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchMachineStatus = async () => {
      try {
        //setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/unit-4/live-data`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        )
        console.log(response)
        if (response.data.success) {
          const machineWiseData = Object.values(response.data.data)
          const data = machineWiseData
            .filter((machine) => (machine && machine.machine ? true : false))
            .map((machine, i) => {
              return {
                index: i + 1,
                machine: capitalizeFirstLetter(machine.machine),
                name: machine.partName,
                type: machine.partType,
                partCount: machine.partCount,
                rejectionCount: machine.rejectionCount,
                machineStatus: machine.machineStatus,
                operator: machine.operator,
                //idleTime: machine.
              }
            })
          setMachineData(data)
          //showNotification(`Machine status fetched successfully`, "success");
        } else {
          showNotification(response.data.message, 'error')
        }
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetchMachineStatus()
    const timeId = setInterval(() => {
      try {
        fetchMachineStatus()
      } catch (error) {
        console.log(error)
      }
    }, 10 * 1000)

    return () => {
      clearInterval(timeId)
    }
  }, [])

  const machineColumns = [
    {
      Header: 'S.No',
      accessor: 'index',
    },
    {
      Header: 'Machine',
      accessor: 'machine',
    },
    {
      Header: 'Operator',
      accessor: 'operator',
    },
    {
      Header: 'Part number',
      accessor: 'name',
    },
    {
      Header: 'Operation',
      accessor: 'type',
    },
    {
      Header: 'Part count',
      accessor: 'partCount',
    },
    {
      Header: 'Rejection count',
      accessor: 'rejectionCount',
    },
    // {
    //   Header: "Idletime",
    //   accessor: "idletime",
    // },
    {
      Header: 'Status',
      accessor: 'machineStatus',
      Cell: ({ cell: { row } }) => {
        //console.log("row.values.machineStatus", row.values.machineStatus)
        return (
          <RippleEffect
            machineStatus={
              row.values.machineStatus === true ||
              row.values.machineStatus === 1
                ? false
                : true
            }
          />
        )
      },
    },
  ]

  return (
    <StyledTableContainer>
      {machineData.length === 0 && (
        <StyledEmptyHeader>
          Live data not available try after some time
        </StyledEmptyHeader>
      )}
      {machineData.length > 0 && (
        <StyledTable
          pagination={false}
          columns={machineColumns}
          data={machineData}
          hiddenColumns={[]}
        />
      )}
    </StyledTableContainer>
  )
}

export default LiveDataTabular
