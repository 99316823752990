import React from 'react'

import { styled } from '@mui/material';


const DivPageContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: 20,
  padding: "0px 24px 0px 24px",
}));

const StyledPageContainer = (props) => {
  const { children } = props

  return <DivPageContainer>{children}</DivPageContainer>;
}

export default StyledPageContainer
