import React from 'react'

import classes from './Pagination.module.css'

const Pagination = ({
  pageIndex,
  pageOptions,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
}) => {
  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <div>
          <span>
            Page
            <strong style={{ marginLeft: '5px' }}>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
        </div>

        {/* <div>
        <span>
          Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            min={1}
            max={pageOptions.length}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
      </div> */}
        <div className={classes.btnContainer}>
          <button
            className={classes.btn}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <i className='fa fa-arrow-left' aria-hidden='true'></i>
          </button>
          <button
            className={classes.btn}
            style={{marginLeft: "8px"}}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <i className='fa fa-arrow-right' aria-hidden='true'></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Pagination
