import React, { useEffect } from 'react'
import { Link, Outlet } from 'react-router-dom'

//context
import ErrorBoundary from '../../context/ErrorBoundary'

//components
import SubMenu from './Submenu'
import {
  DivRoot,
  StyledDrawer,
  DivDrawerContainer,
  DivLogoBox,
  DivNavBox,
  MainContent,
  DivToolbar,
  ImgLogo,
} from './StyledComponents'

//config
import { SidebarData } from './sidebarData'

//styles and assets
import Logo from '../../assets/logo.png'

const Workspace = () => {
  useEffect(() => {}, [])

  return (
    <DivRoot>
      <StyledDrawer variant='permanent'>
        <DivDrawerContainer>
          <DivLogoBox>
            <Link to='/'>
              <ImgLogo src={Logo} alt='company logo' />
            </Link>
          </DivLogoBox>
          <DivNavBox>
            <div
              styles={{
                top: 0,
                left: '0%',
                position: 'fixed',
                width: '250px',
                height: '100vh',
                display: ' flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: '350ms',
                background: '#15171c',
                zIndex: 10,
              }}
            >
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />
              })}
            </div>
          </DivNavBox>
        </DivDrawerContainer>
      </StyledDrawer>
      <MainContent>
        <DivToolbar>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </DivToolbar>
      </MainContent>
    </DivRoot>
  )
}

export default Workspace
