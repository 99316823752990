import React from 'react'
import AnalyticsCard from '../AnalyticsCard/AnalyticsCard'
import classes from './DashBoardAnalytics.module.css'

const DashBoardAnalytics = (props) => {
  const { data } = props
  const machines = 24
  const tillDate = 26

  let productionEfficiency = 0
  let percentageRejection = 0
  let percentageIdletime = 0
  let totalAchievedQuantity = 0
  let totalTarget = 0
  let totalRejection = 0
  let totalIdletime = 0
  let totalAvailableTime = machines * tillDate * 24 * 60

  if (data && data['prodTableData']) {
    data['prodTableData'].forEach((item) => {
      totalAchievedQuantity += Number(item['achievedQuantity'])
      totalTarget += Number(item['target'])
      totalRejection += Number(item['rejectionTotal'])
      totalIdletime += Number(item['idletimeTotal'])
    })

    productionEfficiency = totalTarget
      ? parseInt((totalAchievedQuantity / totalTarget) * 100)
      : 0
    percentageRejection = totalAchievedQuantity
      ? parseInt((totalRejection / totalAchievedQuantity) * 100)
      : 0
    percentageIdletime = parseInt((totalIdletime / totalAvailableTime) * 100)
  }

  return (
    <div className={classes.analyticsContainer}>
      <AnalyticsCard
        cardName="Production"
        gaugeValue={productionEfficiency}
        totalLabel="Achieved"
        totalValue={totalAchievedQuantity}
        actualLabel="Target"
        actualValue={totalTarget}
      />
      <AnalyticsCard
        cardName="Rejection"
        gaugeValue={percentageRejection}
        totalLabel="Rejection"
        totalValue={totalRejection}
        actualLabel="Produced"
        actualValue={totalAchievedQuantity}
      />
      <AnalyticsCard
        cardName="Idletime"
        gaugeValue={percentageIdletime}
        totalLabel="Idletime"
        totalValue={totalIdletime}
        actualLabel="Available"
        actualValue={totalAvailableTime}
      />
    </div>
  )
}

export default DashBoardAnalytics
