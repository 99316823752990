import React, { useState, useEffect } from "react";
import {useErrorTokenLoading, loading, failure, success} from "../../context/ErrorTokenLoadingContext";
import * as html2canvas from "html2canvas";

import InputHeader from "../../components/Input/InputHeader";
import Loader from "../../components/Loader/Loader";
import OperatorTable from "../../components/OperatorTable/OperatorTable";
import OperatorDetails from "../../components/OperatorDetails/OperatorDetails";

import httpApi from "../../api/httpApi";

import * as DATE_UTILS from "../../services/date.utils";

const OperatorReport = () => {

  const {token, elState, elDispatch} = useErrorTokenLoading();

  const [workingDate, setWorkingDate] = useState(DATE_UTILS.workingDate());
  const [calenderDate, setCalenderDate] = useState(DATE_UTILS.workingDate());
  const [operatorList, setOperatorList] = useState([]);
  const [currentOperator, setCurrentOperator] = useState("SELECT OPERATOR")
  const [operatorData, setOperatorData] = useState([]);
  
  useEffect(() => {
    document.title = `DPS | Operator Report`;
  }, [])

  useEffect(() => {
    const fetchMyOperatorList = async () => {
      try {
        elDispatch(loading());
        const {operatorListData } = await httpApi.getData(token, '/operatorlist', {});
        //console.log(operatorListData);
        setOperatorList(operatorListData);
        elDispatch(success());
      } catch (err) {
        elDispatch(failure(err.response));
        //console.error(err);
      }
    };
    fetchMyOperatorList();
  }, []);

  useEffect(() => {
    const fetchMyAPI = async () => {
      try {
        elDispatch(loading());
        const {operatorsInfo } = await httpApi.getData(token, '/operatorinfo', {currentDate: workingDate, operator:currentOperator});
        //console.log(operatorsInfo);
        setOperatorData(operatorsInfo);
        elDispatch(success());
      } catch (err) {
        elDispatch(failure(err.response));
        //console.error(err);
      }
    };
    fetchMyAPI();
  }, [workingDate, currentOperator, token]);

  const dateChangeHandler = (e) => {
    setCalenderDate(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //setWorkingDate(e.target.form.queryDate.value);
    setWorkingDate(calenderDate);
  };

  const prevNextHandler = (actionType) => {
    setCalenderDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
    setWorkingDate((prevDate) => DATE_UTILS.updateDate(actionType, prevDate));
  };

  const operatorChangeHandler = (value) => {
    setCurrentOperator(value.label)
  }

  const takeASnap = () => {
    let screenshotDiv = document.querySelector("#operator-data");
    html2canvas(screenshotDiv).then((canvas) => {
      var a = document.createElement("a");
      a.href = canvas
        .toDataURL("image/png")
        .replace("image/jpeg", "image/octet-stream");
      a.download = "somefilename.jpg";
      a.click();
    });
  };

  return (
    <div className="max-container">
      <InputHeader
        date={calenderDate}
       // header="Operator Performance"
        dateChangeHandler={dateChangeHandler}
        submitHandler={submitHandler}
        prevNextHandler={prevNextHandler}
        currentOperator={currentOperator}
        operatorList={operatorList}
        operatorChangeHandler={operatorChangeHandler}
      />
      {elState.loading && <Loader />}
      {!elState.loading && (
        <>
            <div className="card-container flex-row border-thin mt-24" id="operator-data">
              <div className="card-header bg-light ">
                <h3>Operators Performance Table</h3>
                <span className="cam1-icons">
                  <i
                    className="fa fa-camera-retro ico-lg"
                    title="Take a snap"
                    onClick={() => takeASnap()}
                  ></i>
                </span>
              </div>
              <div className="card-body">
               { currentOperator === "SELECT OPERATOR" && <h1 style={{textAlign: "center"}}>Please select an operator</h1>}
               { operatorData.length === 0 && currentOperator !== "SELECT OPERATOR" && <h1 style={{textAlign: "center"}}>There is no data for this operator</h1>}
               { operatorData.length > 0 && currentOperator !== "SELECT OPERATOR" && <OperatorTable operatorData={operatorData} />}
              </div>
            </div>
        </>
      )}
    </div>
  );
};

export default OperatorReport;
