import React from "react";
import classes from"./RadialGauge.module.css";

const RadialGauge = ({value}) => {
 
  const strokeWidth = (width) => {
    let value = 402.124 - 402.124 * (width / 100);
    // console.log(width, "Incoming request ::", value);
    return value;
  };

  return (
    <div className={classes.gaugeItem}>
      <svg id="gaugeCPU" className={classes.svgBox}>
        <defs>
          <linearGradient id="MyGradient">
            {/* <stop offset="5%" stopColor="#85d134" />
                <stop offset="60%" stopColor="#f78e25" />
                <stop offset="95%" stopColor="#ed0505" /> */}
          </linearGradient>
        </defs>
        <circle
          cx="70"
          cy="70"
          r="64"
          className="shadow"
          style={{
            strokeDashoffset: strokeWidth(Number(value)),
            stroke: value > 85 ? "#85d134" : "#c00000",
          }}
        ></circle>
        <circle cx="70" cy="70" r="64"></circle>
        <text className={classes.svgBoxText} x="75" y="90" textAnchor="middle">
          <tspan>{(Math.round(Number(value) * 100) / 100).toFixed(2)}%</tspan>
        </text>
      </svg>
    </div>
  );
};

export default RadialGauge;
