import React from "react";
import { dayGetter } from '../../services/date.utils'
import RadialGauge from "../RadialGauge/RadialGauge";

import classes from './OperatorDetails.module.css';

const OperatorDetails = ({ operatorData }) => {

  const { daysInMonth, monthOfReport } = dayGetter(operatorData[0]["date_of_job"]);

  const getData = (operatorDataArray) =>{
    const unique = [];
    const uniqueStrinArray = [];
    let count = 0;
    let totalTarget = 0;
    operatorDataArray.forEach((item)=>{
      const uinqueString = `${item["date_of_job"]}-${item["machine"]}-${item["shift"]}`
      if(!unique.includes(item["date_of_job"])){
        unique.push(item["date_of_job"])
       count += 1;
      }
      if(!uniqueStrinArray.includes(uinqueString)){
        uniqueStrinArray.push(uinqueString)
        totalTarget += Number(item["target"]);
      }
    })
    return { count , totalTarget }
  }

  const {count: noOfDaysWorked , totalTarget} =   getData(operatorData)

  const totalAchieved = operatorData.reduce(
    (accu, element) => accu + Number(element["achieved_qty"]),
    0
  );

  const totalIdletime = operatorData.reduce(
    (accu, element) => accu + Number(element["idletime"]),
    0
  );

  const totalRejection = operatorData.reduce(
    (accu, element) => accu + Number(element["rejection"]),
    0
  );

  const attendenceRate = parseInt((noOfDaysWorked/daysInMonth) * 100);
  const achievedRate = parseInt((totalAchieved / totalTarget) * 100);
  const acceptanceRate = parseInt(100 - (totalRejection / totalAchieved) * 100);
  const availabiltyRate = parseInt(100 - (totalIdletime / (noOfDaysWorked * 650)) * 100);

  const indicatorValue = (attendenceRate + achievedRate + acceptanceRate + availabiltyRate) / 4;
  return (
    <div className="container flex-column" style={{ width: "98%" }}>
      <div className={classes.detailHeader}>
        <div className={classes.headerItem}>
          <h4 className={classes.headerValue}>
            Operator Name:
            <span className={classes.parameterSpan}>
              {operatorData[0]["operator"]}
            </span>
          </h4>
        </div>
        <div className={classes.headerItem}>
          <h4 className={classes.headerValue}>
            Operator ID:
            <span className={classes.parameterSpan}>
              {operatorData[0]["operator_id"]}
            </span>
          </h4>
        </div>
        <div className={classes.headerItem}>
          <h4 className={classes.headerValue}>
            Month of Reporting:
            <span className={classes.parameterSpan}>{monthOfReport}</span>
          </h4>
        </div>
      </div>
      <hr style={{ width: "98%" }} className="hr" />
      <div className={classes.details}>
        <div className={classes.parametersConatiner}>
          <div className={classes.progressItem}>
            <div className={classes.progressLabel}>
              <h4>No of Days worked</h4>
              <h5 className={classes.parameterValue}>
                {noOfDaysWorked} / {daysInMonth}
              </h5>
            </div>
            <div className={classes.progressValue}>
              <h4 className={classes.small}>
              Attendance Rate
                <span className="float-right">
                  {(attendenceRate > 100 ? 100 : attendenceRate) + "%"}
                </span>
              </h4>
              <div className={classes.progressBarContainer}>
                <div
                  className={`${classes.progressBar} bg-info`}
                  style={{ width:attendenceRate +"%" }}
                ></div>
              </div>
            </div>
          </div>
          <div className={classes.progressItem}>
            <div className={classes.progressLabel}>
              <h4> Achieved Vs Target </h4>
              <h5 className={classes.parameterValue}>
                {totalAchieved} / {totalTarget}
              </h5>
            </div>
            <div className={classes.progressValue}>
              <h4 className={classes.small}>
                Achieved Rate
                <span className="float-right">
                  {achievedRate + "%"}
                </span>
              </h4>
              <div className={classes.progressBarContainer}>
                <div
                  className={`${classes.progressBar} bg-warning`}
                  style={{width: achievedRate + "%"}}
                ></div>
              </div>
            </div>
          </div>
          <div className={classes.progressItem}>
            <div className={classes.progressLabel}>
              <h4>Total Rejection</h4>
              <h5 className={classes.parameterValue}> {totalRejection}</h5>
            </div>
            <div className={classes.progressValue}>
              <h4 className={classes.small}>
                Acceptance Rate
                <span className="float-right">
                  {acceptanceRate + "%"}
                </span>
              </h4>
              <div className={classes.progressBarContainer}>
                <div
                  className={`${classes.progressBar} bg-danger`}
                  style={{ width: acceptanceRate +"%"}}
                ></div>
              </div>
            </div>
          </div>
          <div className={classes.progressItem}>
            <div className={classes.progressLabel}>
              <h4>Total Idletime</h4>
              <h5 className={classes.parameterValue}> {totalIdletime}</h5>
            </div>
            <div className={classes.progressValue}>
              <h4 className={classes.small}>
              Availability Rate <span className="float-right">{availabiltyRate + "%"}</span>
              </h4>
              <div className={classes.progressBarContainer}>
                <div
                  className={`${classes.progressBar} bg-success`}
                  style={{ width: availabiltyRate + "%" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.gaugeContainer}>
          <h4 className={classes.small}>Performance Indicator </h4>
          <RadialGauge value={indicatorValue}/>
        </div>
      </div>
    </div>
  );
};

export default OperatorDetails;